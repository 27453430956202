<template>
    <div class="home">
        <ul class="menu">
            <li class="oneli"  v-for="oneLevel in chapterList" :key="oneLevel.chapterId">
                <div v-show="oneLevel.chapterType===1" class="onediv"  @click="oneLevel.expanded = !oneLevel.expanded">
                    <span>{{oneLevel.chapterName}}</span>
                    <i class="el-icon-arrow-down" v-show="!oneLevel.expanded"/>
                    <i class="el-icon-arrow-up"  v-show="oneLevel.expanded" />
                </div>
                <!-- 二级目录 -->
                <ul v-show="oneLevel.chapterType===1"  v-if="oneLevel.expanded">
                    <li class="twoli" v-for="twoLevel in oneLevel.children" :key="twoLevel.chapterId">
                        <div v-show="twoLevel.chapterType===2" class="twodiv" @click="twoLevel.expanded = !twoLevel.expanded">
                            <span>{{twoLevel.chapterName}}</span>
                            <i class="el-icon-arrow-down" v-show="!twoLevel.expanded"/>
                            <i class="el-icon-arrow-up"  v-show="twoLevel.expanded" />
                        </div>
                        <!-- 三级目录 -->
                        <ul v-show="twoLevel.chapterType===2" v-if="twoLevel.expanded">
                            <li class="threeli" v-for="threeLevel in twoLevel.children" :key="threeLevel.chapterId" :class="[threeLevel.chapterId === chapterId? 'selected' : '']">
                                <div class="threediv" @click="toPlay(threeLevel)">
                                    <!-- <i v-show="threeLevel.canFree===1 && threeLevel.videoId" type="md-arrow-dropright-circle" size="16"/>
                                    <i v-show="threeLevel.canFree===0" type="md-lock" size="16"/> -->
                                    <i v-show="threeLevel.canFree===1 && threeLevel.videoId" class="el-icon-video-camera"></i>
                                    <i v-show="threeLevel.canFree===0" class="el-icon-lock"></i>
                                    <span @mouseover="getChapterQr(threeLevel.courseId, threeLevel.chapterId)" class="title" :title="threeLevel.chapterName">{{threeLevel.chapterName}}</span>
                                    <span v-if="threeLevel.studyTime" :class="threeLevel | colorFilter">{{threeLevel | statusFilter}}</span>
<!--                                    <span :class="[threeLevel.canFree===0 ? 'start startno' : 'start']" @click="toPlay(threeLevel)">开始学习</span>-->
                                    <span :class="[threeLevel.canFree===0 ? 'start startno' : 'start']" v-show="threeLevel.pointId"  @click.stop="toExam(threeLevel.pointId, threeLevel.canFree)">课后练习</span>
                                    <span v-if="threeLevel.videoId" class="time">{{threeLevel.videoTimeStr}}</span>
                                    <span v-else class="time">无视频</span>
                                    <div class="showqr">
                                        <div>
                                            <img :src="chapterImageUrl">
                                            <p style="font-size: 12px; line-height: 13px; color: #666666">微信扫码,手机观看</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div v-show="twoLevel.chapterType===3" class="threediv"  @click="toPlay(twoLevel)" :class="[twoLevel.chapterId === chapterId? 'selected' : '']">
                            <!-- <Icon v-show="twoLevel.canFree===1 && twoLevel.videoId" type="md-arrow-dropright-circle" size="16"/>
                            <Icon v-show="twoLevel.canFree===0" type="md-lock" size="16"/> -->
                            <i v-show="twoLevel.canFree===1 && twoLevel.videoId" class="el-icon-video-camera"></i>
                            <i v-show="twoLevel.canFree===0" class="el-icon-lock"></i>
                            <span @mouseover="getChapterQr(twoLevel.courseId, twoLevel.chapterId)" class="title" :title="twoLevel.chapterName">{{twoLevel.chapterName}}</span>
                            <span v-if="twoLevel.studyTime" :class="twoLevel | colorFilter">{{twoLevel | statusFilter}}</span>
<!--                            <span :class="[twoLevel.canFree===0 ? 'start startno' : 'start']" @click="toPlay(twoLevel)">开始学习</span>-->
                            <span :class="[twoLevel.canFree===0 ? 'start startno' : 'start']" v-show="twoLevel.pointId"  @click.stop="toExam(twoLevel.pointId, twoLevel.canFree)">课后练习</span>
                            <span v-if="twoLevel.videoId" class="time">{{twoLevel.videoTimeStr}}</span>
                            <span v-else class="time">无视频</span>

                            <div class="showqr">
                                <div>
                                    <img :src="chapterImageUrl">
                                    <p style="font-size: 12px; line-height: 13px; color: #666666">微信扫码,手机观看</p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div  v-show="oneLevel.chapterType===3"  class="threediv"  @click="toPlay(oneLevel)" :class="[oneLevel.chapterId === chapterId? 'selected' : '']">
                    <!-- <Icon v-show="oneLevel.canFree===1 && oneLevel.videoId" type="md-arrow-dropright-circle" size="16"/>
                    <Icon v-show="oneLevel.canFree===0" type="md-lock" size="16"/> -->
                    <i v-show="oneLevel.canFree===1 && oneLevel.videoId" class="el-icon-video-camera"></i>
                    <i v-show="oneLevel.canFree===0" class="el-icon-lock"></i>
                    <span @mouseover="getChapterQr(oneLevel.courseId, oneLevel.chapterId)" class="title" :title="oneLevel.chapterName">{{oneLevel.chapterName}}</span>
                    <span v-if="oneLevel.studyTime" :class="oneLevel | colorFilter">{{oneLevel | statusFilter}}</span>
<!--                    <span :class="[oneLevel.canFree===0 ? 'start startno' : 'start']" @click="toPlay(oneLevel)">开始学习</span>-->
                    <span :class="[oneLevel.canFree===0 ? 'start startno' : 'start']" v-show="oneLevel.pointId"  @click.stop="toExam(oneLevel.pointId, oneLevel.canFree)">课后练习</span>
                    <span v-if="oneLevel.videoId" class="time">{{oneLevel.videoTimeStr}}</span>
                    <span v-else class="time">无视频</span>

                    <div class="showqr">
                        <div>
                            <img :src="chapterImageUrl">
                            <p style="font-size: 12px; line-height: 13px; color: #666666">微信扫码,手机观看</p>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        props: {
            chapterId: {
                type: Number,
                default: 0
            },
            chapterList: {
                type: Array,
                default() {
                    return []
                }
            },
        },
        components: {
        },
        filters: {
            colorFilter(item) {
                return "process"
                // if (item.studyStatus && item.studyStatus === 1) {
                //     return "process"
                // } else {
                //     return "process"
                // }
            },
            statusFilter(item) {
                if (item.studyStatus && item.studyStatus === 1) {
                    return "已学完"
                } else {
                    if (item.studyTime) {
                        let proses = item.studyTime / item.videoTime * 100;
                        return '已学' + proses.toFixed(0) + '%';
                    } else {
                        return '';
                    }

                }
            },
        },
        data () {
            return {
                chapterImageUrl: ''
            }
        },
        methods: {
            getChapterQr: function (courseId, chapterId) {
                this.chapterImageUrl = process.env.VUE_APP_API_HOST + 'notify/verify/getQr?bizType=COURSE&bizId=' + courseId + '&chapterId=' + chapterId + "&tenantId=" + this.appCookies.getTenantId();
            },
            toExam: function (pointId, can) {
                this.$emit("chapterExam", pointId, can)
            },
            toPlay: function (chapter) {
                if (chapter.videoId) {
                    this.$emit("play", chapter)
                }
            },
        }
    }
</script>

<style scoped lang='scss'>
    .home {
        width: 100%;
        height: 100%;
    }
    .menu {
        list-style: none;
        margin: 0;
        padding-left: 0;
        background-color: #fff;
        width: 100%;
        .oneli {
            border-bottom: 1px solid #f5f6f7;
            border-radius: 2px;
            width: 100%;
            .showqr {
                display: none;
                position: absolute;
                top: 40px;
                background-color: #FFFFFF;
                height: 160px;
                width: 160px;
                background-clip: padding-box;
                border-radius: 4px;
                -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 20%);
                box-shadow: 0 1px 6px rgba(0, 0, 0, 20%);
                white-space: nowrap;
                z-index: 999999;
                padding: 5px 5px;
                div {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 130px;
                        height: 130px;
                    }
                    p {
                        font-size: 14px;
                    }
                }
            }
            .onediv {
                display: flex;
                flex-direction: row;
                align-items: center;
                line-height: 24px;
                border-radius: 2px;
                margin: 16px 0px;
                cursor: pointer;
                list-style: none;
                white-space: nowrap;
                span {
                    flex-grow: 1;
                    display: inline-block;
                    max-width: calc(100% - 28px);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 20px;
                    font-weight: 400;
                    color: #959595;;
                }
            }
            .threediv:hover .showqr {
                display: block
            }
            .threediv {
                display: flex;
                flex-direction: row;
                align-items: center;
                line-height: 24px;
                padding-top: 16px;
                padding-bottom: 16px;
                margin: 0px 0px;
                width: 100%;
                cursor: pointer;
                list-style: none;
                white-space: nowrap;
                position: relative;
                .title {
                    padding-left: 10px;
                    padding-right: 10px;
                    flex: 1;
                    max-width: calc(100% - 108px);
                    overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                    font-size: 16px;
                    font-weight: normal;

                    width: 0;
                }
                .tag {
                    display: inline-block;
                    width: 32px;
                    height: 16px;
                    background-size: contain;
                    margin-left: 8px;
                }
                .start {
                    height: 32px;
                    background: #fc5531;
                    border-radius: 16px;
                    padding: 0 15px;
                    font-size: 14px;
                    line-height: 32px;
                    color: #fff;
                    /*display: none;*/
                    cursor: pointer;
                    margin: 0 2px;
                }
                .startno {
                    background: #cccccc;
                }
                .process {
                    height: 32px;
                    font-size: 14px;
                    line-height: 32px;
                    color: #3e8eff;
                    margin: 0 4px;
                    /*height: 20px;*/
                    /*font-size: 10px;*/
                    /*line-height: 22px;*/
                    /*color: #999999;*/
                    /*margin: 0 2px;*/
                }
                .time {
                    line-height: 32px;
                    font-size: 12px;
                    color: #555666;
                    min-width: 32px;
                    padding: 0 5px;
                }
            }
            .selected {
                background-color: #FFFAFA;
                border-radius: 2px;
                color: #fc5531;
            }
            ul {
                list-style: none;
                .twoli:hover .twodiv {
                    background-color: #FFFAFA;
                }
                .twoli {
                    padding-left: 20px;
                    border-bottom: 0px solid #ffffff;
                    width: 100%;
                    .twodiv {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        line-height: 24px;
                        padding-right: 0px;
                        // margin: 16px 0px;
                        padding-top: 16px;
                        padding-bottom: 16px;
                        cursor: pointer;
                        list-style: none;
                        white-space: nowrap;
                        span {
                            flex-grow: 1;
                            display: inline-block;
                            max-width: calc(100% - 28px);
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 18px;
                            font-weight: 400;
                            /*padding-left: 10px;*/
                            /*border-left: #3398D4 3px solid;*/
                        }
                    }
                    .threediv {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        line-height: 24px;
                        padding-top: 16px;
                        padding-bottom: 16px;
                        margin: 0px 0px;
                        width: 100%;
                        cursor: pointer;
                        list-style: none;
                        white-space: nowrap;
                        .title {
                            padding-left: 10px;
                            padding-right: 10px;
                            flex: 1;
                            max-width: calc(100% - 108px);
                            /*overflow: hidden;*/
                            /*text-overflow:ellipsis;*/
                            /*white-space: nowrap;*/
                            font-size: 16px;
                            font-weight: normal;
                        }
                        .tag {
                            display: inline-block;
                            width: 32px;
                            height: 16px;
                            background-size: contain;
                            margin-left: 8px;
                        }
                        .start {
                            height: 32px;
                            background: #fc5531;
                            border-radius: 16px;
                            padding: 0 15px;
                            font-size: 14px;
                            line-height: 32px;
                            color: #fff;
                            /*display: none;*/
                            cursor: pointer;
                            margin: 0 2px;
                        }
                        .startno {
                            background: #cccccc;
                        }
                        .time {
                            line-height: 32px;
                            font-size: 12px;
                            color: #555666;
                            min-width: 32px;
                            padding: 0 5px;
                        }
                    }
                    .selected {
                        background-color: #FFFAFA;
                        border-radius: 2px;
                        color: #fc5531;
                    }
                    div:hover {
                        background-color: #FFFAFA;
                        border-radius: 2px;
                        color: #fc5531;
                    }
                    div:hover .start {
                        display: block;
                    }
                    ul {
                        list-style: none;
                        .threeli {
                            font-size: 14px;
                            padding-left: 20px;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            .threediv {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                line-height: 24px;
                                margin: 0px 0px;
                                width: 100%;
                                .title {
                                    padding-left: 10px;
                                    padding-right: 10px;
                                    flex: 1;
                                    max-width: calc(100% - 108px);
                                    /*overflow: hidden;*/
                                    /*text-overflow:ellipsis;*/
                                    /*white-space: nowrap;*/
                                    font-size: 16px;
                                    font-weight: normal;
                                }
                                .tag {
                                    display: inline-block;
                                    width: 32px;
                                    height: 16px;
                                    background-size: contain;
                                    margin-left: 8px;
                                }
                                .start {
                                    height: 32px;
                                    background: #fc5531;
                                    border-radius: 16px;
                                    padding: 0 15px;
                                    font-size: 14px;
                                    line-height: 32px;
                                    color: #fff;
                                    /*display: none;*/
                                    cursor: pointer;
                                    margin: 0 2px;
                                }
                                .startno {
                                    background: #cccccc;
                                }
                                .time {
                                    line-height: 32px;
                                     font-size: 12px;
                                     color: #555666;
                                     min-width: 32px;
                                    padding: 0 5px;
                                 }
                            }
                        }
                        .selected {
                            background-color: #FFFAFA;
                            border-radius: 2px;
                            color: #fc5531;
                        }
                        li:hover {
                            background-color: #FFFAFA;
                            border-radius: 2px;
                            color: #fc5531;
                        }
                        li:hover .start {
                            display: block;
                        }
                    }
                }
            }
        }
    }
</style>
