<template>
  <div class="article-viewer">
    <div class="article-content" v-html="htmlContent"></div>
  </div>
</template>

<script>
export default {
  props: {
    htmlContent: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
.article-viewer {
  height: 100%;
  background-color: #FFFFFF;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  overflow: auto;
}
.article-content{
  max-width: 80%;
}
</style>
