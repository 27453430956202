<template>
    <div class="audio-player">
        <div class="audio-img-wrapper">
            <img class="audio-img" v-if="audioOptions.posterImg" :src="audioOptions.posterImg" alt=""/>
            <img
                class="audio-img"
                v-else
                src="@/assets/images/common/audioDefault.jpg"
                alt=""
            />
        </div>
        <div class="audio-wrapper">
            <div class="time-slider">
                <el-slider
                    v-model="sliderValue"
                    :step='1'
                    :max="sliderLength"
                    :show-tooltip="true"
                    :disabled="audioInfo.fastForward"
                    :format-tooltip="formatTooltip"
                    @change="sliderChange"
                    @input="sliderInput"
                ></el-slider>
            </div>
            <div class="audio-btns">
                <div class="play-btn">
                    <i :class="[audioInfo.playing?'el-icon-video-pause':'el-icon-video-play']" style="font-size:50px"
                       @click="play"></i>
                </div>
                <div class="time-msg">
                    {{ currentTimeStr }}&emsp;/&emsp;{{ audioInfo.totleTimeStr }}
                </div>
                <div class="speed" v-if="!audioInfo.fastForward">
                    <div class="speed-msg" @click="audioInfo.showSpeedList = !audioInfo.showSpeedList">
                        {{ audioInfo.currentSpeed }}x
                    </div>
                    <div class="speed-list" v-show="audioInfo.showSpeedList">
                        <div class="speed-item" v-for="(item, idx) in audioInfo.speedList" :key="idx"
                             @click="setSpeed(item)">{{ item }}x
                        </div>
                    </div>
                </div>
                <div class="volume">
                    <div class="volume-icon">
                        <i class="el-icon-headset"></i>
                    </div>
                    <div class="volume-slider">
                        <el-slider
                            v-model="audioInfo.volumeValue"
                            @change="setVolume" >
                        </el-slider>
                    </div>
                </div>
                <audio
                    class="my-audio"
                    id="audio"
                    controlsList="nodownload"
                    @timeupdate="timeUpdate($event)"
                    @canplay="canplay"
                    @ended="onPlayerEnded"
                >
                    <source :src="this.audioOptions.playPathUrl" type="audio/mp3"/>
                    您的浏览器不支持 audio 播放器。
                </audio>
                <!-- <div class="next-btn" @click="nextAudio">
                  <i class="el-icon-caret-right btn-icon"></i>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        audioOptions: {
            type: Object,
            default () {
                return {}
            }
        },
        dragable: {
            type: Boolean,
            default() {
                return true;
            },
        },
    },
    watch: {
        audioOptions: {
            //播放资源变化时，重新加载audio元素，初始化播放起始位置
            handler(val) {
                this.$nextTick(() => {
                    this.audio.load();
                    console.log(this.audio);
                    this.audio.currentTime = this.audioOptions.currentTime;
                    this.audioInfo.speedList = this.audioOptions.playbackRates;
                    this.currentTime = this.audioOptions.currentTime;
                    this.lastStudyTime = this.audioOptions.currentTime;
                    this.lastExamTime = 0;
                    this.audioInfo.fastForward = this.audioOptions.fastForward === 0 && this.audioOptions.studyStatus !== 1; // 验证能否快进
                    this.audioInfo.hasInit = false; // 数据变化设置为未初始化
                    this.initAudio();
                })
            },
            deep: true,
            immediate: true
        },
    },
    data() {
        return {
            audio: null,
            //el滑块属性
            sliderValue: 0,
            sliderLength: 100,
            disabled: false,
            newTime: '',
            audioInfo: {
                hasInit: false,
                playing: false,
                fastForward: true, // 能否快进
                totleTimeStr: '',
                showSpeedList: false,
                currentSpeed: 1,
                speedList: [0.25, 0.5, 1, 1.5, 2],
                volumeValue: 20,
            },
            currentTime: 0, // 当前播放时间
            lastExamTime: 0, // 最近记录弹题监控时间
            lastStudyTime: 0, // 最近记录的学习时间
        };
    },
    computed: {
        currentTimeStr: function () {
            return this.formatTime(this.sliderValue)
        }
    },
    mounted() {
        this.audio = document.getElementById("audio");
    },
    methods: {
        //audio钩子函数canplay，音频准备好播放时触发，此时才可以获取音频总时长
        initAudio: function () {
            if (!this.audioInfo.hasInit && this.audio.duration) {
                this.sliderValue = this.currentTime;//设置进度条进度等于学习记录
                this.audio.currentTime = this.currentTime;//设置播放进度等于学习记录
                this.sliderLength = this.audio.duration;//设置滑动条长度等于音频长度
                this.audioInfo.totleTimeStr = this.formatTime(this.audio.duration)
                this.audioInfo.hasInit = true;
            }
        },
        canplay: function () {
            if (!this.audioInfo.hasInit) {
                this.initAudio();
            }
        },
        //audio事件，播放位置变化时即时触发
        timeUpdate(e) {
            // console.log(this.audio.currentTime);
            // this.newtime = Math.floor(this.audio.currentTime);
            // this.sliderValue = this.newtime;
            // if (this.newtime > this.studyTime) {
            //     this.$emit("timeUpdate", this.newtime);
            // }
            // //音频播放完后
            // if (this.audio.currentTime === this.audio.duration) {
            //     this.audioInfo.playing = false;
            //     this.disabled = false;
            // }
            this.currentTime = parseInt(this.audio.currentTime, 0);
            this.sliderValue = this.currentTime;
            // 播放一定的时间进行弹题操作
            if (this.audioOptions.playExam !== 0 && this.appCookies.hasLogin()) {
                if (!this.lastExamTime) {
                    this.lastExamTime = this.currentTime;
                }
                // console.log(this.currentTime - this.lastExamTime)
                // console.log(this.audioOptions.playExamMinute * 60)
                if ((this.currentTime - this.lastExamTime) > this.audioOptions.playExamMinute * 60) {
                    this.$emit("confirmIsHuman") // 确认是否是真人答题 或随机试题
                    this.lastExamTime = this.currentTime;
                }
            }
            // 记录播放进度
            if (!this.appCookies.hasLogin() || this.audioOptions.studyStatus === 1) {
                return;
            }
            if ((this.currentTime - this.lastStudyTime) > 60) {
                this.lastStudyTime = this.currentTime
                this.$emit("updProgress", this.currentTime);
            }

        },
        onPlayerEnded: function (player) {
            this.$emit('onPlayerEnd', parseInt(this.audio.duration, 0));
        },
        //拖拽进度条滑块松开鼠标时触发
        sliderChange(newValue) {
            this.audio.currentTime = newValue;
        },
        //进度条变化过程中即时触发
        sliderInput(newValue) {
            if (this.audio.currentTime < newValue) {
                this.audio.currentTime = newValue;
            } else if ((this.audio.currentTime - newValue) > 1.5) {
                this.audio.currentTime = newValue;
            }
        },
        //设置滑动条显示信息
        formatTooltip(value) {
            return this.formatTime(value)
        },
        formatTime: function (val) {
            let minute = Math.floor(val / 60);
            let second = Math.floor(val % 60);
            if (second < 10) {
                second = '0' + second;
            }
            return minute + ":" + second;
        },
        //自定义播放、暂停按钮
        play() {
            if (this.audioInfo.playing === false) {
                this.audio.play()
                this.audioInfo.playing = true;
            } else {
                this.audio.pause();
                this.audioInfo.playing = false;
            }
        },
        //设置速度
        setSpeed(speed) {
            // console.log(speed);
            this.audioInfo.currentSpeed = speed;
            this.audio.playbackRate = speed;
            // console.log(this.audio.playbackRate);
            this.audioInfo.showSpeedList = false;
        },
        //设置音量
        setVolume(volume) {
            this.audio.volume = volume / 100;
        },
    },
};
</script>

<style scoped lang="scss">
.my-audio {
    width: 50%;
}

.audio-player {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #404040;
}

.audio-img-wrapper {
    flex-grow: 1;
    box-sizing: border-box;
    overflow: hidden;
    text-align: center;

    .audio-img {
        height: 100%;
    }
}

.audio-wrapper {
    background-color: #fff;
    padding: 0 20px;

    .time-slider {
    }

    .audio-btns {
        box-sizing: border-box;
        height: 80px;
        padding-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        .last-btn,
        .next-btn {
            box-sizing: border-box;
            width: 54px;
            height: 54px;
            line-height: 54px;
            border-radius: 50%;
            border: 2px solid #1890ff;
            margin: 0 20px;
            cursor: pointer;

            .btn-icon {
                font-size: 50px;
                color: #1890ff;
            }
        }

        .play-btn {
            color: #1890ff;
            cursor: pointer;
            border-radius: 50%;
            margin: auto 10px;
        }

        .play-btn:hover {
            background-color: #e9f3fd;
            color: #1890ff;
        }

        .time-msg {
            margin: auto 10px
        }

        .speed {
            position: relative;
            margin: auto 10px;
            cursor: pointer;
            width: 56px;
            box-sizing: border-box;

            .speed-msg {
                padding: 10px 20px;
                width: 40px;
            }

            .speed-list {
                position: absolute;
                padding-bottom: 8px;
                bottom: 20px;
                left: 0;
                z-index: 9999;

                .speed-item {
                    background-color: #fff;
                    padding: 4px 20px;
                }

                .speed-item:hover {
                    background-color: #dcdcdc;
                }
            }
        }

        .volume {
            display: flex;
            width: 200px;
            margin: auto 20px;

            .volume-icon {
                font-size: 20px;
                color: #1890ff;
                line-height: 38px;
                margin-right: 20px;
            }

            .volume-slider {
                flex-grow: 1;
            }
        }
    }
}
</style>
