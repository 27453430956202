<template>
<div>
    <video-player class="video-player vjs-custom-skin"
                  ref="videoPlayer" id="videoPlayer"
                  :playsinline="true"
                  @play="onPlayerPlay($event)"
                  @ended="onPlayerEnded($event)"
                  @timeupdate = "onPlayerTimeupdate($event)"
                  @error="onPlayerError($event)"
                  :options="playerOptions">
    </video-player>
</div>
</template>

<script>
import videojs from 'video.js';
export default {
    components: {
    },
    props: {
        videoOptions: {
            type: Object,
            default () {
                return {}
            }
        },
    },
    watch: {
        videoOptions: {
            //播放资源变化时，重新加载audio元素，初始化播放起始位置
            handler(val) {
                this.$nextTick(() => {
                    this.playerOptions.playbackRates = this.videoOptions.playbackRates;
                    this.playerOptions['sources'][0].src = this.videoOptions.playPathUrl;
                    this.playerOptions.poster = this.videoOptions.posterImg;
                    this.currentTime = this.videoOptions.currentTime;
                    this.lastStudyTime = this.videoOptions.currentTime;
                    this.lastExamTime = 0;
                    this.clearTimeEightTop();
                    this.hasExtends = false;
                })
            },
            deep: true,
            immediate: true
        },
    },
    data () {
        return {
            // player: '',
            playerOptions: {
                playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                autoplay: false, // 如果为true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 是否视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{
                    type: "video/mp4", // 类型
                    src: '' // url地址 https://stream7.iqilu.com/10339/upload_transcode/202002/18/20200218114723HDu3hhxqIT.mp4
                }],
                poster: '', // 封面地址
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true // 是否显示全屏按钮
                }
            },
            playing: false,
            currentTime: 0,
            lastExamTime: 0,
            lastStudyTime: 0,
            hasExtends: false,
        }
    },
    // 离开页面后销毁播放器，定时器
    beforeDestroy() {
        this.clearTimeEightTop();
    },
    computed: {
        player() {
            // document.getElementById('videoPlayer').appendChild(this.$refs.examModal.$el)
            return this.$refs.videoPlayer.player
        }
    },
    mounted() {
        this.stopPlayer();
    },
    methods: {
        play: function () {
            this.player.play();
        },
        pause: function () {
            this.player.pause();
        },
        // 视频播放
        onPlayerPlay: function (player) {
            if (this.videoOptions.fastForward === 0) {
                if (this.videoOptions.studyStatus !== 1) {
                    // 禁用拖动
                    document.querySelector(".vjs-progress-control").style.visibility = "hidden";
                    document.querySelector(".vjs-progress-control").style.pointerEvents = "none";
                } else {
                    document.querySelector(".vjs-progress-control").style.visibility = "visible";
                    document.querySelector(".vjs-progress-control").style.pointerEvents = "auto";
                }
            }
            // 视频总时长
            // console.log(player.cache_.duration)
            if (this.appCookies.hasLogin()) {
                if (this.hasExtends === false) {
                    this.createMyButton(this.appCookies.getCookiePhnoe())
                    this.hasExtends = true;
                } else {
                    this.setTimeEightTop();
                }
            }
            player.currentTime(this.currentTime); // 跳转
            this.currentTime = parseInt(player.cache_.currentTime, 0);
        },
        onPlayerError: function (player) {
            this.player.errorDisplay.close();   // 将错误信息不显示
            this.$message({
                message: '网络错误，请检查网络后重试',
                showClose: true, offset: 400, type: 'error'
            });
        },
        onPlayerEnded: function (player) {
            this.clearTimeEightTop()
            this.$emit('onPlayerEnd', parseInt(player.cache_.currentTime, 0));
        },
        /* 获取视频播放进度 */
        onPlayerTimeupdate (player) {
            this.currentTime = parseInt(player.cache_.currentTime, 0);
            // 记录播放进度
            if (!this.appCookies.hasLogin() || this.videoOptions.studyStatus === 1) {
                return;
            }
            // 播放一定的时间进行弹题操作
            if (this.videoOptions.playExam !== 0 && this.appCookies.hasLogin()) {
                if (!this.lastExamTime) {
                    this.lastExamTime = this.currentTime;
                }
                // console.log(this.currentTime - this.lastExamTime)
                // console.log(this.videoOptions.playExamMinute * 60)
                if ((this.currentTime - this.lastExamTime) > this.videoOptions.playExamMinute * 60) {
                    if (this.player.isFullscreen()) {
                        this.player.exitFullscreen(); // 强制退出全屏，恢复正常大小
                    }
                    // alert(2)
                    // this.toRandomItem();
                    this.$emit("confirmIsHuman") // 确认是否是真人答题 或随机试题
                    this.lastExamTime = this.currentTime;
                }
            }
            if ((this.currentTime - this.lastStudyTime) > 60) {
                this.lastStudyTime = this.currentTime
                this.$emit("updProgress", this.currentTime);
            }
        },
        stopPlayer: function () {
            try {
                let self = this
                document.addEventListener("visibilitychange", function() {
                    // console.log(document.visibilityState)
                    if (document.visibilityState === "hidden") {
                        // 切离该页面时执行
                        let isPaused = self.player.tech_.el_.paused;
                        // console.log(isPaused)
                        if (!isPaused) {
                            self.player.pause();
                        } else {
                            // this.player.play();
                        }
                    } else if (document.visibilityState === "visible") {
                        // 切换到该页面时执行
                        // self.getImage();
                    }
                });
            } catch (e) {
                console.log(e)
            }

        },
        // 设置8秒定时器，这个8秒是和css里面的动画循环时间一致的
        setTimeEightTop: function () {
            let self = this;
            if (!self.player.getChild('RandomButton')) {
                this.createMyButton(this.appCookies.getCookiePhnoe())
                this.hasExtends = true;
            }
            self.timeEight = setInterval(() => {
                self.animationTextTop = (Math.random() * 80 + 5) + '%';
                if (self.player && self.player.getChild('RandomButton')) {
                    self.player.getChild('RandomButton').eventBusEl_.style.top = self.animationTextTop;
                }
            }, 8000)
        },
        // 清楚定时器
        clearTimeEightTop: function () {
            clearInterval(this.timeEight);
        },
        createMyButton: function (showText) {
            let Button = videojs.getComponent('Button')
            // console.log(Button)
            let RandomButton = videojs.extend(Button, {
                // 这个就这么抄着，必需
                constructor: function (player, options) {
                    Button.apply(this, arguments)
                },
                // 自定义组件被点击后的事件
                handleClick: () => {
                    // let isPaused = this.player.tech_.el_.paused;
                    // if (!isPaused) {
                    //     this.player.pause();
                    // } else {
                    //     this.player.play();
                    // }
                },
                // 创建一个DOM元素
                createEl: function() {
                    // 这个类名在css中要用到，很重要
                    let divObj = videojs.dom.createEl('div', {
                        className: 'momodefine',
                    });
                    videojs.dom.appendContent(divObj, this.options_.text)
                    return divObj;
                },
            })
            // 注册视频中随机位置滚动的组件
            videojs.registerComponent('RandomButton', RandomButton);
            // 将组件添加到播放器中
            this.player.addChild('RandomButton', {
                text: showText
            });
            // 调用定时器
            this.setTimeEightTop();
        },
    }
}
</script>

<style scoped>

</style>
