<template>
  <div class="image-viewer" v-if="imgList.length>0">
    <div class="mode-btns">
        <el-button-group>
            <el-button :type="modeShowAll?'':'primary'" icon="el-icon-menu" @click="modeShowAll = false"></el-button>
            <el-button :type="modeShowAll?'primary':''" icon="el-icon-s-operation" @click="modeShowAll = true"></el-button>
        </el-button-group>
    </div>
    <div v-if="modeShowAll" class="img-container long-container" v-infinite-scroll="load" :infinite-scroll-distance="10" style="overflow:auto;">
        <div v-for="(item, idx) in loadedImgList" :key="idx">
            <img class="img"  :src="item.imagePathUrl" alt="" />
        </div>
    </div>
    <div v-else class="img-container width-container">
      <div class="img-wrapper">
        <img class="img single-img" :src="imgList[currentIndex].imagePathUrl" alt="" />
      </div>
      <div class="left-arrow" >
          <img src="@/assets/images/main/arrow1.png" alt="" @click="lastPage" />
      </div>
        <div class="right-arrow">
            <img src="@/assets/images/main/arrow2.png" alt="" @click="nextPage" />
        </div>

    </div>
    <div v-if="!modeShowAll" class="page-msg">{{ currentIndex + 1 }}/{{ imgList.length }}</div>
    <div v-else class="page-msg">{{ loadedImgList.length }}/{{ imgList.length }}</div>
  </div>
</template>

<script>
export default {
  props: {
    imgList: {
      type: Array,
      default() {
        return [];
      },
    },
    maxRecord: {
      type: Number,
      default() {
        return 0;
      },
    },
    showAll: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      modeShowAll: false,
      loadedImgList: [],
      currentIndex: 0, //分页预览时的当前页索引
    };
  },
  watch: {
    imgList: {
        handler(val) {
            this.loadedImgList = [];
            this.loadedImgList.push(this.imgList[0]);
            this.modeShowAll = this.showAll;
            this.currentIndex = this.maxRecord;
        },
        deep: true,
        immediate: true,
    },
    showAll: {
      handler(val) {
        this.modeShowAll = val;
      },
      deep: true,
      immediate: true,
    },
    modeShowAll(val) {
      this.$emit("update:showAll", val);//提交到父组件，因为父容器需根据不同模式设置不同高度
    },
  },
  methods: {
    lastPage() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      } else {
        this.$message({
            message: '已是第一页！',
            showClose: true, offset: 200, type: 'warning'
        });
      }
    },
    nextPage() {
      if (this.currentIndex < this.imgList.length - 1) {
        this.currentIndex++;
        //大于最大阅读记录时更新父组件maxRecord
        if (this.currentIndex > this.maxRecord) {
          this.$emit("updateMaxRecord", this.currentIndex)
        }
      } else {
        this.$message({
            message: '已是最后一页！',
            showClose: true, offset: 200, type: 'warning'
        });
      }
    },
    load() {
        console.log('loading...');
            if (this.loadedImgList.length<this.imgList.length) {
                let index = this.loadedImgList.length
                this.loadedImgList.push(this.imgList[index])
                console.log(index);
                console.log(this.loadedImgList);
                //大于最大阅读记录时更新父组件maxRecord
                if (this.loadedImgList.length > this.maxRecord) {
                  this.$emit("updateMaxRecord", this.loadedImgList.length)
                }
            }
    },
  },
};
</script>

<style scoped lang="scss">
.image-viewer {
  height: 100%;
  position: relative;
}
.mode-btns {
  position: sticky;
  top: 0;
  z-index: 99;
  text-align: right;
  margin-top: -36px;
  .mode-btn {
    display: inline-block;
    box-sizing: border-box;
    background-color: white;
    border: 1px solid black;
    padding: 4px 8px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    border-radius: 2px;
    cursor: pointer;
    color: black;
  }
  .mode-btn-choosed {
    background-color: rgb(32, 132, 255);
    color: white;
  }
}
.img-container {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #404040;
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 1200px) {
  .img-container {
    width: 1170px;
  }
}
@media (max-width: 992px) {
  .img-container {
    width: 970px;
  }
}
@media (max-width: 768px) {
  .img-container {
    width: 750px;
  }
}
.long-container {
  box-sizing: border-box;
  height: 100%;
  .img {
    width: 100%;
    margin: 10px auto;
  }
}
.width-container {
  text-align: center;
  height: 100%;
  .img-wrapper {
    overflow: hidden;
    height: 100%;
    width: 100%;
    .img {
    }
    .single-img{
      height: 100%;
      max-width: 100%;
    }
  }
  .left-arrow {
    position: absolute;
      width: 80px;
      height: 80px;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    background-color: rgba(64, 64, 64, 0.3);
      border-radius: 40px;
      text-align: center;
      img {
          width: 40px;
          height: auto;
          margin-top: 20px;
      }
  }
  .right-arrow {
    position: absolute;
      width: 80px;
      height: 80px;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    background-color: rgba(64, 64, 64, 0.3);
      border-radius: 40px;
      text-align: center;
      img {
          width: 40px;
          height: auto;
          margin-top: 20px;
      }
  }
}
.page-msg {
  position: absolute;
  left: 8px;
  bottom: -20px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  color: white;
    font-weight: bold;
  //background-color: rgba(64, 64, 64, 0.4);
}
</style>
