<template>
    <el-drawer
        title="课程详情"
        :visible.sync="detailDrawer"
        :modal-append-to-body="true"
        :wrapperClosable="false"
        :destroy-on-close="true"
        direction="rtl"
        custom-class="demo-drawer"
        size="90%"
    >
        <div class="demo-drawer__content">
            <el-row>
                <el-col :span="24">
                    <div class="course-detail">
                        <!--        <div class="course-banner">-->
                        <!--            <img src="@/assets/images/common/courseBanner.png" alt="">-->
                        <!--        </div>-->
                        <div class="course-msg">
                            <div class="course-msg-header">课程信息</div>
                            <div class="course-msg-main">
                                <div class="course-vedio-player" @click="toCoursePlayer">
                                    <img class="course-vedio-img" :src="info.courseImgUrl" alt="">
                                    <span></span>
                                    <img class="course-vedio-btn" src="../../../assets/images/course/courseIcon8.png" alt="">
                                </div>
                                <div class="course-msg-box">
                                    <h1>{{ info.courseName }}</h1>
                                    <div class="course-msg-line2">
                                        <span>{{ info.gnvqName }} {{ info.levelName }}</span>
                                        <!-- <span>授课讲师：XXX</span> -->
                                    </div>
                                    <div class="course-msg-line2">
                                        <span>课时：共{{ statInfo.videoCount }}课时</span>
                                        <span>时长：{{ statInfo.courseTime }}</span>
                                    </div>
                                    <div class="course-msg-price">
                                        <span class="course-msg-currentPrice">价格：￥{{ info.timesAmount }}元/人次</span>
                                        <span class="course-msg-originalPrice" v-if="info.coursePrice">{{ info.coursePrice }}</span>
                                    </div>
                                    <div class="course-msg-btns">
                                        <button class="course-msg-btn" v-if="info.orgId!==appCookies.getCookieOID()" @click="toBuy(info)">立即购买</button>
                                    </div>
                                </div>
                            </div>
                            <div class="course-vedio-detail">
                                <div class="course-vedio-left">
                                    <div class="course-left-nav">
                                        <div class="course-nav-item" :class="[detaileType==='chapterList'?'course-nav-active':'']"
                                             @click="changeDetaileType('chapterList')">课程章节
                                        </div>
                                        <div class="course-nav-item" :class="[detaileType==='courseAbs'?'course-nav-active':'']"
                                             @click="changeDetaileType('courseAbs')">课程介绍
                                        </div>
                                    </div>
                                    <div class="course-left-content">
                                        <CourseChapter
                                            v-if="detaileType==='chapterList'"
                                            @chapterExam="toChapterExam"
                                            :chapterId="chapterInfo.chapterId"
                                            @play="toPlay"
                                            :chapterList="chapterList">
                                        </CourseChapter>
                                        <div v-if="detaileType==='courseAbs'">
                                            <h2 style="line-height:30px;font-size:18px">课程介绍</h2>
                                            <p style="margin:16px auto;line-height:30px;font-size:18px">{{ info.courseAbs }}</p>
                                            <div style="margin:16px auto" v-html="info.courseContent"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="course-vedio-right">
                                    <div class="course-teacher" v-if="teacherList.length>0">
                                        <div class="course-teacher-header">讲师介绍</div>
                                        <div class="course-teacher-item" v-for="(item, idx) in teacherList" :key="idx">
                                            <div class="teacher-item-top">
                                                <div class="teacher-item-img">
                                                    <img v-if="item.headImg" :src="item.headImg" alt="">
                                                    <div v-else style="color:#ffffff; background-color:#e9e9e9;width: 100px;height: 100px;border-radius: 50%;"></div>
                                                </div>
                                                <div class="teacher-item-msg">
                                                    <div class="teacher-item-name">{{ item.teacherName }}</div>
                                                    <div>{{ item.jobTitleName }}</div>
                                                    <div>{{ item.jobTitleDesc }}</div>
                                                </div>
                                            </div>
                                            <div class="teacher-item-intro">
                                                <div>{{ item.teacherAbs }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="hot-tag">
                                        <div class="hot-tag-header">热门标签</div>
                                        <span>电工</span>
                                        <span>茶艺师</span>
                                        <span>营养师</span>
                                        <span>安全员</span>
                                        <span>育婴员</span>
                                        <span>物业管理员</span>
                                        <span>保育员</span>
                                        <span>焊工</span>
                                        <span>质检员</span>
                                        <span>培训师</span>
                                        <span>设计师</span>
                                    </div>
                                    <div class="recommend">
                                        <div class="recommend-header">推荐课程</div>
                                        <div class="recommend-list">
                                            <div class="recommend-list-item" v-for="(item, idx) in recommendList" :key="idx">
                                                <a class="course-vedio-player" :href="'/main/coursedetail?courseId=' + item.courseId"
                                                   target="blank">
                                                    <img class="course-vedio-img" :src="item.courseImgUrl" alt="">
                                                </a>
                                                <div class="recommend-item-msg">
                                                    <h2>
                                                        <a :href="'/main/coursedetail?courseId=' + item.courseId">
                                                            {{ item.courseName }}
                                                        </a>
                                                    </h2>
                                                    <div class="recommend-item-gnvq">{{ item.gnvqName }}-{{ item.levelName }}</div>
                                                    <div>{{ item.publishTime | parseTime('{y}-{m}-{d}') }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="demo-drawer__footer">
            <el-button @click="detailDrawer = false">关 闭</el-button>
        </div>
        <CoursePlayerDrawer ref="coursePlayerDrawer"></CoursePlayerDrawer>
    </el-drawer>
</template>

<script>
import CourseChapter from "@v/shop/components/CourseChapter.vue";
import CoursePlayerDrawer from './CoursePlayerDrawer'
export default {
    components: {
        CourseChapter,
        CoursePlayerDrawer
    },
    filters: {
    },
    data() {
        return {
            detailDrawer: false,
            mainLoading: false,
            mainLoadingText: '请稍等，正在处理',
            courseId: '',
            info: {},
            chapterInfo: {},
            statInfo: {},
            detaileType: 'chapterList',
            chapterList: [],
            teacherList: [],
            recommendList: [],
            tempList: [
                {
                    chapterName: '章chapterName',
                    chapterType: 1,
                    children: [
                        {
                            chapterName: '节chapterName',
                            chapterType: 2,
                            children: [
                                {
                                    chapterName: '知识点chapterName',
                                    chapterType: 3,
                                    children: [],
                                },
                            ],
                        },
                        {
                            chapterName: '节chapterName',
                            chapterType: 2,
                            children: [],
                        },
                        {
                            chapterName: '节chapterName',
                            chapterType: 2,
                            children: [],
                        },
                    ],
                },
                {
                    chapterName: '章chapterName',
                    chapterType: 1,
                    children: [
                        {
                            chapterName: '知识点chapterName',
                            chapterType: 3,
                            children: [],
                        },
                    ],
                },
                {
                    chapterName: '节chapterName',
                    chapterType: 2,
                    children: [
                        {
                            chapterName: '知识点chapterName知识点chapterName知识点chapterName知识点chapterName',
                            chapterType: 3,
                            children: [],
                        },
                    ],
                },
                {
                    chapterName: '知识点chapterName知识点chapterName知识点chapterName',
                    chapterType: 3,
                    children: [],
                },
                {
                    chapterName: '知识点chapterName',
                    chapterType: 3,
                    children: [],
                },
            ],
        }
    },
    methods: {
        open: function (courseId) {
            this.courseId = courseId;
            this.detailDrawer = true;
            this.getDetail(this.courseId);
            this.getChapterList(this.courseId);
            this.getTeacherList(this.courseId);
            this.getStat(this.courseId);
            this.getRecommendList();
        },
        toBuy: function (item) {
           // this.$refs.courseBuyDrawer.open(item.courseId)
            this.$emit('callback', item.courseId)
            this.detailDrawer = false
        },
        toBuyBak: function () {
            this.mainLoading = true
            let that = this;
            // 创建订单
            let order = {
                userId: this.appCookies.getCookieUID(),
                courseId: that.courseId,
                priceValue: that.courseInfo.salePrice,
                payWay: that.orderInfo.payWay,
            }
            this.apis.postJson('user/courseOrder/create', order, false, true)
                .then((res) => {
                    if (res.code === 1) {
                        // 立即支付
                        let result = res.data;
                        this.orderInfo = res.data;
                        if (result.payStatus === 1 && result.priceValue === 0) {
                            that.finished(result.orderId, result.payStatus)
                            // this.toSubsMsg(true, '')
                        } else {
                            this.createPayOrder(result.orderId, result.priceValue)
                        }
                    } else {
                        this.$message({ message: res.msg, showClose: true, offset: 50, type: 'error' });
                        this.mainLoading = false
                    }
                }).catch((err) => {
                this.mainLoading = false
                console.log(err)
            });

        },
        getDetail: function (courseId) {
            let data = {
                courseId: courseId,
                hasLogin: this.appCookies.hasLogin(),
                userId: this.appCookies.getCookieUID()
            }
            this.apis.get('portal/course/getById', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.info = res.data || {};
                        // this.playerOptions.poster = this.info.courseImgUrl;
                    } else {
                        this.info = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        getChapterList: function (courseId) {
            let data = {
                hasLogin: this.appCookies.hasLogin(),
                userId: this.appCookies.getCookieUID()
            }
            this.apis.get('portal/course/getChapterList/' + courseId, data)
                .then((res) => {
                    if (res.code === 1) {
                        this.chapterList = res.data || [];
                    } else {
                        this.chapterList = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        getTeacherList: function (courseId) {
            this.apis.get('portal/course/getCourseTeacher/' + courseId)
                .then((res) => {
                    if (res.code === 1) {
                        this.teacherList = res.data || [];
                    } else {
                        this.teacherList = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        getStat: function (courseId) {
            this.apis.get('portal/course/getStat?courseId=' + courseId)
                .then((res) => {
                    if (res.code === 1) {
                        this.statInfo = res.data || {};
                    } else {
                        this.statInfo = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        getRecommendList: function () {
            let data = {
                pageNumber: 0,
                pageSize: 4,
                gnvqCatId: '',
                gnvqId: '',
                levelId: '',
                keyword: '',
            }
            this.apis.postForm('portal/course/query', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.recommendList = res.data || [];
                        console.log(res);
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        toenroll: function (info) {
            let data = {
                currentStep: 0,
                courseId: info.courseId
            }
            //  如果已经登录
            if (this.appCookies.hasLogin()) {
                this.$router.push({
                    path: '/main/buycourse',
                    query: data
                });
            } else {
                //  children  路由可以直接使用this.$parent.父页面的方法名  直接调用
                sessionStorage.setItem('redirectUrl', '/main/buycourse?' + this.apis.adornData(data, false, 'form'));
                // console.log(this.$parent);
                //未登录打开登录界面
                this.$parent.toLogin();
            }
        },
        toenrollClass: function (item) {
            let data = {
                currentStep: 1,
                categoryId: item.gnvqCatId,
                gnvqId: item.gnvqId,
                levelId: item.levelId,
                orgId: item.orgId,
                classId: item.classId,
            }
            //  如果已经登录
            if (this.appCookies.hasLogin()) {
                this.$router.push({
                    path: '/main/enroll',
                    query: data
                });
            } else {
                //  children  路由可以直接使用this.$parent.父页面的方法名  直接调用
                sessionStorage.setItem('redirectUrl', '/main/enroll?' + this.apis.adornData(data, false, 'form'));
                console.log(this.$parent);
                //未登录打开登录界面
                this.$parent.toLogin();
            }
        },
        toService: function () {
            window.open('https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97817701c426469ce919e2b860ce41accff3847368b806f82b92e9c88ceafc65b6975b717efcafe2d05cbbc4f3a8f89514', '_blank')
        },
        changeDetaileType: function (detaileType) {
            this.detaileType = detaileType;
        },
        // 点击进入其他视频详情页面
        toCourseDetail(courseId) {
            this.$router.push({
                path: '/main/coursedetail',
                query: { courseId: courseId }
            })
        },
        toChapterExam: async function (pointId, can) {
            if (this.appCookies.hasLogin() && can === 1) {
                let data = {
                    courseId: this.info.courseId,
                    gnvqId: this.info.gnvqId,
                    levelId: this.info.levelId,
                    pointId: pointId,
                    userId: this.appCookies.getCookieUID()
                }
                let count = await this.getRandomItemCount();
                if (count === 0) {
                    this.$message({
                        message: "此章节无课后练习题",
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    return;
                }
                this.apis.get('/user/enrollOrder/checkHasEnrollByGnvq', data, false, true)
                    .then((res) => {
                        if (res.code === 1) {
                            let data = {
                                userId: this.appCookies.getCookieUID(),
                                courseId: this.info.courseId,
                                gnvqId: this.info.gnvqId,
                                levelId: this.info.levelId,
                                pointId: pointId,
                                exerciseType: 'COURSE',
                                regen: 0
                            }
                            this.apis.postForm('user/exercise/genExerciseByGnvq', data, false, true)
                                .then((res) => {
                                    if (res.code === 1) {
                                        this.$router.push({
                                            path: '/main/exam/exercise',
                                            query: {
                                                mstId: res.data.mstId
                                            }
                                        });
                                    } else if (res.code === -2) {
                                        this.$router.push({
                                            path: '/main/exam/exercise',
                                            query: {
                                                mstId: res.data.mstId
                                            }
                                        });
                                    } else {
                                        this.$message({
                                            message: res.msg,
                                            showClose: true,
                                            offset: 200,
                                            type: 'error'
                                        });
                                    }
                                }).catch(err => {
                                console.log(err);
                            });
                        } else {
                            this.toLoginOrEnroll();
                            // this.$Modal.warning({
                            //     title: '您未报名此工种等级',
                            //     content: '请确报名后操作'
                            // });
                        }
                    }).catch(err => {
                    console.log(err);
                });
                // this.$router.push({
                //     path: '/main/exam/practice',
                //     query: {
                //         courseId: this.info.courseId,
                //         chapterId: chapterId,
                //         userId: this.appCookies.getCookieUID()
                //     }
                // });
            } else {
                this.toLoginOrEnroll();
                // this.$Modal.warning({
                //     title: '未登录或未报名不可进行课后练习',
                //     content: '请确认是否已经登录或已报名此工种等级'
                // });
            }
        },
        getRandomItemCount: async function (data) {
            await this.apis.get('user/exercise/getRandomItemCount', data, false, true)
                .then((res) => {
                    if (res.code === 1) {
                        return res.data;
                    } else {
                        return 0;
                    }
                }).catch(err => {
                    console.log(err);
                    return 0;
                });
        },
        toLoginOrEnroll: function () {
            if (!this.appCookies.hasLogin()) {
                this.$parent.toLogin()
            } else {
                this.$confirm('未报名或学习已结束,请报名后操作', '提示', {
                    confirmButtonText: '去报名',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$router.push({
                        path: '/main/enroll',
                        query: {
                            categoryId: this.info.gnvqCatId,
                            gnvqId: this.info.gnvqId,
                            levelId: this.info.levelId
                        }
                    });
                }).catch(() => {
                });
            }
        },
        toCoursePlayer: function () {
            // this.$router.push({
            //     path: '/courseplayer',
            //     query: { courseId: this.info.courseId }
            // })
            this.$refs.coursePlayerDrawer.open(this.info.courseId)
        },
        toPlay: function (chapter) {
            this.$refs.coursePlayerDrawer.open(chapter.courseId)
            // if (chapter.canFree === 1) {
            //     this.$router.push({
            //         path: '/courseplayer',
            //         query: { courseId: chapter.courseId }
            //     })
            // } else {
            //     //非免费章节
            //     if (!this.appCookies.hasLogin()) {
            //         this.$parent.toLogin();
            //     } else {
            //         this.$confirm('未报名或学习已结束,请报名后操作', '提示', {
            //             confirmButtonText: '去报名',
            //             cancelButtonText: '取消',
            //             type: 'warning'
            //         }).then(() => {
            //             this.$router.push({
            //                 path: '/main/enroll',
            //                 query: {
            //                     categoryId: this.info.gnvqCatId,
            //                     gnvqId: this.info.gnvqId,
            //                     levelId: this.info.levelId
            //                 }
            //             });
            //         }).catch(() => {
            //         });
            //     }
            // }
        },
    }
}
</script>


<style scoped lang="scss">
* {
    margin: 0;
}

.course-detail {
    padding-top: 2px;
    padding-bottom: 50px;
}

.course-banner {
    width: 100%;
    height: 200px;
    img {
        width: 100%;
        height: 100%;
    }
}

.course-msg {
    width: 1200px;
    margin: 0 auto;
    margin-top: 24px;

    .course-msg-header {
        border-left: 4px solid #9E9E9B;
        padding-left: 10px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
        margin-bottom: 10px;
    }

    .course-msg-main {
        border-top: 1px solid #EDEDED;
        padding-top: 24px;
        display: flex;
        padding-bottom: 24px;
        border-bottom: 1px solid #EDEDED;;

        .course-vedio-player {
            display: inline-block;
            width: 540px;
            height: 340px;
            position: relative;

            .course-vedio-img {
                width: 100%;
                height: 100%;
            }

            span {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.4);
                cursor: pointer;
            }

            .course-vedio-btn {
                height: 104px;
                width: 104px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
            }
        }

        .course-msg-box {
            margin-left: 26px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            h1 {
                width: 634px;
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                padding-bottom: 30px;
            }

            .course-msg-line2 {
                // line-height: 30px;
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;

                span {
                    padding-right: 16px;
                }
            }

            .course-msg-price {
                width: 629px;
                height: 48px;
                line-height: 48px;
                background: #FFE2E0;
                margin-top: 40px;
                margin-bottom: 40px;
                padding-left: 28px;
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ED4239;

                .course-msg-currentPrice {
                    font-size: 24px;
                    padding-right: 32px;
                }

                .course-msg-originalPrice {
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    text-decoration: line-through;
                    color: #666666;
                }
            }

            .course-msg-btns {

                .course-msg-btn {
                    border: none;
                    width: 150px;
                    height: 40px;
                    background-color: #ED4239;
                    border-radius: 20px;
                    cursor: pointer;
                    font-size: 20px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin-right: 30px;
                }

                .green-btn {
                    background: #39EDD1;
                }
            }
        }
    }

    .course-vedio-detail {
        display: flex;
        margin-top: 24px;

        .course-vedio-left {
            flex-grow: 1;
            border: 1px solid #EDEDED;
            padding-left: 36px;
            // padding-right: 24px;
            padding-bottom: 30px;
            margin-right: 14px;

            .course-left-nav {
                display: flex;
                justify-content: left;
                margin-bottom: 20px;

                .course-nav-item {
                    width: 178px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    background: #F2F1F7;
                    margin-right: 5px;
                    cursor: pointer;
                    font-size: 20px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                }

                .course-nav-active {
                    background: #EB2418;
                    color: #FFFFFF;
                }
            }
            .class-main-content {
                .class-main-item{
                    background: #FFFFFF;
                    margin: 16px 0;
                    padding: 16px;
                    display: flex;
                    border: 1px solid #DEDEDE;
                    border-radius: 4px;
                    .class-item-img {
                        width: 300px;
                        height: 168px;
                        border-radius: 4px;
                    }
                    .class-item-msg{
                        flex-grow: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding-left: 16px;
                        width: 0;
                        overflow: hidden;
                        .class-item-title{
                            display: inline-block;
                            font-size: 20px;
                            font-family: Microsoft YaHei;
                            font-weight: 500;
                            color: #333333;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .class-item-Num {
                            // padding-left: 30px;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #1C1F21;
                            opacity: 0.8;
                        }
                        .class-item-org{
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #1C1F21;
                        }
                        .class-item-gnvq{
                            span{
                                display: inline-block;
                                text-align: center;
                                min-width: 80px;
                                padding: 6px 8px;
                                margin-right: 14px;
                                color: #FFFFFF;
                                font-size: 12px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                            }
                            .class-item-categoryName{
                                background: #6EC681;
                            }
                            .class-item-gnvqName{
                                background: #EB2418;
                            }
                            .class-item-levelName{
                                background: #409AE8;
                            }
                        }
                        .class-item-footer{
                            display: flex;
                            justify-content: space-between;
                            span{
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #1C1F21;
                                opacity: 0.7;
                            }
                            .class-item-endDate{
                            }
                        }
                        .class-item-handle{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .class-item-price{
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #FD0D06;
                                line-height: 25px;
                                span{
                                    font-size: 18px;
                                }
                            }
                            .class-item-btn{
                                display: inline-block;
                                text-align: center;
                                padding: 4px 8px;
                                // line-height: 30px;
                                background: #EB2418;
                                border-radius: 4px;
                                font-size: 16px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #FFFFFF;
                            }
                        }
                        .class-item-end{
                            display: inline-block;
                            text-align: center;
                            padding: 4px 8px;
                            width: 68px;
                            border-radius: 4px;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #FFFFFF;
                            background-color: #000000;
                            opacity: 0.47;
                        }
                    }
                }
            }
        }

        .course-vedio-right {
            width: 300px;

            .course-teacher {
                border: 1px solid #EDEDED;
                padding: 20px;
                margin-bottom: 16px;

                .course-teacher-header {
                    font-size: 20px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                }

                .course-teacher-item {
                    // border-bottom: 2px solid #F4F4F4;
                    margin: 24px auto;

                    .teacher-item-top {
                        display: flex;

                        .teacher-item-img {
                            width: 100px;
                            height: 100px;
                            img {
                                width: 100px;
                                height: 100px;
                                border-radius: 50%;
                            }
                        }

                        .teacher-item-msg {
                            margin-left: 16px;

                            div {
                                line-height: 26px;
                                font-size: 16px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #999999;
                            }

                            .teacher-item-name {
                                font-size: 20px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #666666;
                                margin-bottom: 24px;
                            }
                        }
                    }

                    .teacher-item-intro {
                        margin-top: 10px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #999999;
                        line-height: 1.5;
                        overflow: hidden; //首先固定宽高，然后让溢出的文字隐藏
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3; //表示显示的行数
                        -webkit-box-orient: vertical
                    }
                }
            }

            .hot-tag {
                margin-bottom: 10px;

                .hot-tag-header {
                    font-size: 20px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    margin-bottom: 16px;
                }

                span {
                    display: inline-block;
                    padding: 10px;
                    background: #F4F4F4;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                    margin-right: 10px;
                    margin-bottom: 10px;
                }
            }

            .recommend {
                .recommend-header {
                    font-size: 20px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    margin-bottom: 16px;
                }

                .recommend-list {
                    .recommend-list-item {
                        display: flex;
                        margin: 10px auto;

                        img {
                            width: 95px;
                            height: 66px;
                        }

                        .recommend-item-msg {
                            margin-left: 20px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-around;
                            overflow: hidden;

                            h2 {
                                font-size: 16px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #666666;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                            .recommend-item-gnvq{
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }

                            div {
                                font-size: 16px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #999999;
                            }
                        }
                    }
                }
            }
        }
    }
}

.other-course {
    padding-top: 54px;

    .other-course-header {
        text-align: center;
        padding-bottom: 40px;

        .enTitle {
            font-size: 28px;
            font-family: Microsoft YaHei;
            color: #333333;
        }

        .chTitle {
            font-size: 28px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 16px;
            margin-top: 16px;

            .chTitleLine {
                display: inline-block;
                width: 80px;
                height: 1px;
                background: #000000;
                margin: 0 16px;
            }
        }

        .otherHeaderText {
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            line-height: 30px;
        }
    }

    .other-course-main {
        display: flex;
        justify-content: center;
        margin-bottom: 50px;

        .other-course-item {
            cursor: pointer;
            width: 285px;
            margin-right: 20px;
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            // height: 200px;
            .other-course-player {
                width: 285px;
                height: 200px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .other-course-player {
                display: inline-block;
                width: 285px;
                height: 200px;
                position: relative;

                .course-vedio-img {
                    width: 100%;
                    height: 100%;
                }

                span {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background-color: rgba(0, 0, 0, 0.4);
                }

                .course-vedio-btn {
                    width: 55px;
                    height: 55px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            h2 {
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                padding-left: 10px;
                padding-top: 16px;
                padding-right: 10px;
                padding-bottom: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .other-item-detail {
                padding-left: 10px;
                padding-right: 10px;
                padding-bottom: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .other-item-category {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #59B38D;
                    width: 180px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .other-item-level {
                    padding: 4px 12px;
                    text-align: center;
                    display: inline-block;
                    background: #f9bdb9;
                    border-radius: 10px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #EB2418;
                }
            }
        }
    }
}

.course-detail-footer {
    text-align: center;

    a {
        display: inline-block;
        padding: 12px 24px;
        border: 2px solid #ED4239;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #EB2418;
    }
}
</style>
