<template>
    <el-drawer
        title="课程学习"
        :visible.sync="playerDrawer"
        :modal-append-to-body="true"
        :append-to-body="true"
        :wrapperClosable="false"
        :destroy-on-close="true"
        direction="rtl"
        custom-class="demo-drawer"
        size="100%"
    >
        <div class="demo-drawer__content">
            <el-row>
                <el-col :span="24">
                    <div class="course-player"  v-loading="videoLoading" :element-loading-text="videoLoadingTxt">
                        <div class="course-player-header">
                            <img class="header-back" width="18" height="18" src="../../../assets/images/course/courseIcon10.png" alt="" @click="goBack">
                            <h2>{{info.courseName}}&emsp;|&emsp;{{chapterInfo.chapterName}}</h2>
                            <el-popover
                                placement="bottom"
                                width="200"
                                trigger="click">
                                <div class="share-qr" style="width:160px;margin: 0 auto;">
                                    <img :src="qrImageUrl" width="160" height="160">
                                    <p style="font-size: 13px; color: #666666; text-align:center">微信扫码，手机观看课程</p>
                                </div>
                                <div slot="reference" class="header-share">
<!--                                    <span>分享</span>-->
<!--                                    <img class="share-btn" width="26px" height="26px" src="../../../assets/images/class/classIcon3.png" alt="" @click="getQrImage">-->
                                </div>
                            </el-popover>
                        </div>
                        <div class="course-player-main">
                            <div class="nav-btns">
                                <div class="nav-btn" @click="showChapterList = !showChapterList">
                                    <img src="../../../assets/images/course/courseIcon11.png" alt="">
                                    <div>章节</div>
                                </div>
                                <div class="nav-btn">
                                    <img src="../../../assets/images/course/courseIcon12.png" alt="">
                                    <div>问答</div>
                                </div>
                                <div class="nav-btn">
                                    <img src="../../../assets/images/course/courseIcon13.png" alt="">
                                    <div>章节</div>
                                </div>
                                <div class="nav-btn">
                                    <img src="../../../assets/images/course/courseIcon14.png" alt="">
                                    <div>问答</div>
                                </div>
                            </div>
                            <div class="chapter-list" v-show="showChapterList">
                                <!-- 一级目录 -->
                                <div
                                    class="list-item list-item-level1"
                                    v-for="oneLevel in chapterList"
                                    :key="oneLevel.chapterId">
                                    <div class="list-item-self">
                                        <div class="list-item-self-line1">
                                            <div class="list-item-self-left">
                                                <!-- <i v-if="oneLevel.chapterType === 3 && oneLevel.canFree===1" class="el-icon-video-play  list-item-self-icon" style="color: #A8A9AB;font-weight: bold;"></i> -->
                                                <img v-if="oneLevel.chapterType === 3 && oneLevel.canFree===1 && oneLevel.relationType==='VIDEO'" class="list-item-self-icon" src="../../../assets/images/course/video.png" alt="">
                                                <img v-if="oneLevel.chapterType === 3 && oneLevel.canFree===1 && oneLevel.relationType==='AUDIO'" class="list-item-self-icon" src="../../../assets/images/course/audio.png" alt="">
                                                <img v-if="oneLevel.chapterType === 3 && oneLevel.canFree===1 && oneLevel.relationType==='ARTICLE'" class="list-item-self-icon" src="../../../assets/images/course/article.png" alt="">
                                                <i v-if="oneLevel.chapterType === 3 && oneLevel.canFree===0" class="el-icon-lock list-item-self-icon" style="color: #A8A9AB;font-weight: bold;"></i>
                                                <h3 :class="[oneLevel.chapterType === 3?'knowledge-item-text' : 'list-item-text']" @click="toPlay(oneLevel)">{{oneLevel.chapterName}}</h3>
                                            </div>
                                            <div class="list-item-self-right" v-if="oneLevel.chapterType === 3">
                                                <span v-show="oneLevel.hasAnnexFile" class="list-item-file-btn" @click="getFiles(oneLevel)">课件</span>
                                                <!-- <button v-show="oneLevel.pointId" @click="toChapterExam(item.pointId, item.canFree)">课后练习</button> -->
                                            </div>
                                        </div>
                                        <div class="list-item-self-line2" v-if="oneLevel.chapterType === 3">
                                            <div class='list-item-qr-box'>
                                                <img src="../../../assets/images/common/qr2.png" class="list-item-qr-icon" width="16" height="16" alt="" @mouseover="getChapterQr(oneLevel.chapterId)">
                                                <div class="list-item-qr" v-if="oneLevel.chapterType === 3">
                                                    <img :src="chapterImageUrl" width="140" height="140">
                                                    <p style="font-size: 12px; line-height: 13px; color: #666666">微信扫码,手机观看</p>
                                                </div>
                                            </div>
                                            <span v-if="oneLevel.videoId" class="time">{{oneLevel.videoTimeStr}}</span>
                                            <span class="list-item-state">{{oneLevel | statusFilter}}</span>
                                            <!-- 测试用，正式使用恢复上一行 -->
                                            <!--                <span class="list-item-state">已学xx%</span>-->
                                            <!-- <button v-show="oneLevel.pointId" @click="toChapterExam(item.pointId, item.canFree)">课后练习</button> -->
                                        </div>
                                    </div>
                                    <!-- 二级目录 -->
                                    <template v-if="oneLevel.children.length>0">
                                        <div
                                            class="list-item list-item-children"
                                            v-for="twoLevel in oneLevel.children"
                                            :key="twoLevel.chapterId">
                                            <div class="list-item-self">
                                                <div class="list-item-self-line1">
                                                    <div class="list-item-self-left">
                                                        <!-- <i v-if="twoLevel.chapterType === 3 && twoLevel.canFree===1" class="el-icon-video-play  list-item-self-icon" style="color: #A8A9AB;font-weight: bold;"></i> -->
                                                        <img v-if="twoLevel.chapterType === 3 && twoLevel.canFree===1 && twoLevel.relationType==='VIDEO'" class="list-item-self-icon" src="../../../assets/images/course/video.png" alt="">
                                                        <img v-if="twoLevel.chapterType === 3 && twoLevel.canFree===1 && twoLevel.relationType==='AUDIO'" class="list-item-self-icon" src="../../../assets/images/course/audio.png" alt="">
                                                        <img v-if="twoLevel.chapterType === 3 && twoLevel.canFree===1 && twoLevel.relationType==='ARTICLE'" class="list-item-self-icon" src="../../../assets/images/course/article.png" alt="">
                                                        <i v-if="twoLevel.chapterType === 3 && twoLevel.canFree===0" class="el-icon-lock list-item-self-icon" style="color: #A8A9AB;font-weight: bold;"></i>
                                                        <h3 :class="[twoLevel.chapterType === 3?'knowledge-item-text' : 'list-item-text']" @click="toPlay(twoLevel)">{{twoLevel.chapterName}}</h3>
                                                    </div>
                                                    <div class="list-item-self-right" v-if="twoLevel.chapterType === 3">
                                                        <span v-show="twoLevel.hasAnnexFile" class="list-item-file-btn" @click="getFiles(twoLevel)">课件</span>
                                                        <!-- <button v-show="oneLevel.pointId" @click="toChapterExam(item.pointId, item.canFree)">课后练习</button> -->
                                                    </div>
                                                </div>
                                                <div class="list-item-self-line2" v-if="twoLevel.chapterType === 3">
                                                    <div class='list-item-qr-box'>
                                                        <img src="../../../assets/images/common/qr2.png" class="list-item-qr-icon" width="16" height="16" alt="" @mouseover="getChapterQr(twoLevel.chapterId)">
                                                        <div class="list-item-qr" v-if="twoLevel.chapterType === 3">
                                                            <img :src="chapterImageUrl" width="140" height="140">
                                                            <p style="font-size: 12px; line-height: 13px; color: #666666">微信扫码,手机观看</p>
                                                        </div>
                                                    </div>
                                                    <span v-if="twoLevel.videoId" class="time">{{twoLevel.videoTimeStr}}</span>
                                                    <span class="list-item-state">{{twoLevel | statusFilter}}</span>
                                                    <!-- 测试用，正式使用恢复上一行 -->
                                                    <!--                        <span class="list-item-state">已学xx%</span>-->
                                                    <!-- <button v-show="oneLevel.pointId" @click="toChapterExam(item.pointId, item.canFree)">课后练习</button> -->
                                                </div>
                                            </div>
                                            <!-- 三级目录 -->
                                            <template v-if="twoLevel.children.length>0">
                                                <div
                                                    class="list-item list-item-children"
                                                    v-for="threeLevel in twoLevel.children"
                                                    :key="threeLevel.chapterId">
                                                    <div class="list-item-self">
                                                        <div class="list-item-self-line1">
                                                            <div class="list-item-self-left">
                                                                <!-- <i v-if="threeLevel.chapterType === 3 && threeLevel.canFree===1" class="el-icon-video-play  list-item-self-icon" style="color: #A8A9AB;font-weight: bold;"></i> -->
                                                                <img v-if="threeLevel.chapterType === 3 && threeLevel.canFree===1 && threeLevel.relationType==='VIDEO'" class="list-item-self-icon" src="../../../assets/images/course/video.png" alt="">
                                                                <img v-if="threeLevel.chapterType === 3 && threeLevel.canFree===1 && threeLevel.relationType==='AUDIO'" class="list-item-self-icon" src="../../../assets/images/course/audio.png" alt="">
                                                                <img v-if="threeLevel.chapterType === 3 && threeLevel.canFree===1 && threeLevel.relationType==='ARTICLE'" class="list-item-self-icon" src="../../../assets/images/course/article.png" alt="">
                                                                <i v-if="threeLevel.chapterType === 3 && threeLevel.canFree===0" class="el-icon-lock list-item-self-icon" style="color: #A8A9AB;font-weight: bold;"></i>
                                                                <h3 :class="[threeLevel.chapterType === 3?'knowledge-item-text' : 'list-item-text']" @click="toPlay(threeLevel)">{{threeLevel.chapterName}}</h3>
                                                            </div>
                                                            <div class="list-item-self-right" v-if="threeLevel.chapterType === 3">
                                                                <span v-show="threeLevel.hasAnnexFile" class="list-item-file-btn" @click="getFiles(threeLevel)">课件</span>
                                                                <!-- <button v-show="oneLevel.pointId" @click="toChapterExam(item.pointId, item.canFree)">课后练习</button> -->
                                                            </div>
                                                        </div>
                                                        <div class="list-item-self-line2" v-if="threeLevel.chapterType === 3">
                                                            <div class='list-item-qr-box'>
                                                                <img src="../../../assets/images/common/qr2.png" class="list-item-qr-icon" width="16" height="16" alt="" @mouseover="getChapterQr(threeLevel.chapterId)">
                                                                <div class="list-item-qr" v-if="threeLevel.chapterType === 3">
                                                                    <img :src="chapterImageUrl" width="140" height="140">
                                                                    <p style="font-size: 12px; line-height: 13px; color: #666666">微信扫码,手机观看</p>
                                                                </div>
                                                            </div>
                                                            <span v-if="threeLevel.videoId" class="time">{{threeLevel.videoTimeStr}}</span>
                                                            <span class="list-item-state">{{threeLevel | statusFilter}}</span>
                                                            <!-- 测试用，正式使用恢复上一行 -->
                                                            <!--                            <span class="list-item-state">已学xx%</span>-->
                                                            <!-- <button v-show="oneLevel.pointId" @click="toChapterExam(item.pointId, item.canFree)">课后练习</button> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="player-box"  v-loading="showSpin" :element-loading-text="spinText">
                                <div class="player-wrapper">
                                    <!--没有视频的时候显示默认图片-->
                                    <div v-show="this.playerOptions.relationType==='' && !endPageShow" class="player-default">
                                        <img :src="this.info.courseImgUrl" alt="">
                                    </div>
                                    <!-- 播放器 -->
                                    <!--          <video-player v-show="this.playerOptions['sources'][0].src && !endPageShow" class="video-player vjs-custom-skin"-->
                                    <!--            ref="videoPlayer" id="videoPlayer"-->
                                    <!--            :playsinline="true"-->
                                    <!--            @play="onPlayerPlay($event)"-->
                                    <!--            @ended="onPlayerEnded($event)"-->
                                    <!--            @timeupdate = "onPlayerTimeupdate($event)"-->
                                    <!--            @error="onPlayerError($event)"-->
                                    <!--            :options="playerOptions">-->
                                    <!--          </video-player>-->
                                    <CVideoPlayer v-if="playerOptions.relationType==='VIDEO'"
                                                  ref="cvideoPlayer"
                                                  :video-options="playerOptions"
                                                  @confirmIsHuman="toConfirmIsHuman"
                                                  @updProgress="updateTime"
                                                  @onPlayerEnd="onPlayerEnd">
                                    </CVideoPlayer>
                                    <AudioPlayer v-if="playerOptions.relationType==='AUDIO'"
                                                 ref="audioPlayer"
                                                 :audio-options="playerOptions"
                                                 @confirmIsHuman="toConfirmIsHuman"
                                                 @updProgress="updateTime"
                                                 @onPlayerEnd="onPlayerEnd">
                                    </AudioPlayer>
                                    <ArticleViewer v-if="playerOptions.relationType==='ARTICLE'"
                                                   :htmlContent="playerOptions.articleContent">
                                    </ArticleViewer>
                                    <ImageViewer  v-if="playerOptions.relationType==='IMAGE'"
                                                  :imgList="playerOptions.imgList">
                                    </ImageViewer>
                                    <!--视频播放完成的尾页-->
                                    <div v-show="endPageShow" class="player-end">
                                        <el-button type="success" round icon="el-icon-caret-right"  @click="playNext">下一节</el-button>
                                        <el-button type="text" icon="el-icon-refresh-right" @click="replay">重新观看</el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="demo-drawer__footer">
            <el-button @click="playerDrawer = false">关 闭</el-button>
        </div>
    </el-drawer>
</template>
<script>
    import CVideoPlayer from "@c/player/CVideoPlayer.vue";
    import AudioPlayer from "@c/player/AudioPlayer.vue";
    import ArticleViewer from '@c/player/ArticleViewer.vue';
    import ImageViewer from '@c/player/ImageViewer.vue'
    export default {
        components: {
            CVideoPlayer,
            AudioPlayer,
            ArticleViewer,
            ImageViewer,
        },
        filters: {
            colorFilter(item) {
                return "process"
                // if (item.studyStatus && item.studyStatus === 1) {
                //     return "process"
                // } else {
                //     return "process"
                // }
            },
            statusFilter(item) {
                if (item.studyStatus && item.studyStatus === 1) {
                    return "已学完"
                } else {
                    if (item.studyTime) {
                        let proses = item.studyTime / item.videoTime * 100;
                        return '已学' + proses.toFixed(0) + '%';
                    } else {
                        return '';
                    }

                }
            },
        },
        data () {
            return {
                playerDrawer: false,
                showChapterList: true,
                videoLoading: false,
                videoLoadingTxt: '数据加载中',
                courseId: '',
                studyId: '',
                fromRefId: '',
                info: {
                    gnvqName: '',
                    levelName: '',
                    courseName: '',
                    courseImgUrl: ''
                },
                chapterList: [],
                chapterInfo: {},
                // player: '',
                playerOptions: { // 统一给各种播放组件使用
                    // 公共
                    relationType: '', //  VIDEO AUDIO ARTICLE IMAGE
                    posterImg: '', // 封面图片
                    playPathUrl: '', // 资源地址
                    articleContent: '', // 图文内容
                    imgList: [], //课件图片内容

                    playbackRates: [0.5, 1.0, 1.5, 2.0], // 视频、音频播放速率
                    // 学习相关
                    currentTime: 0, // 当前学习进度
                    fastForward: 1, // 能否快进
                    studyStatus: 0, // 当前课程学习状态
                    playExam: 0, // 是否弹题、监控、滑块
                    playExamMinute: 0, // 弹题、监控、滑块  频次
                },
                filesList: [], //课件列表
                showAll: false,
                showSpin: false,
                spinText: '正在生成模拟考试试题，请稍等',
                playing: false,
                hasExtends: false,
                qrImageUrl: '',
                chapterImageUrl: '',
                endPageShow: false, // 是否显示视频播放完成尾页
                courseConfig: {
                    fastForward: 1,
                    playExam: 0,
                    playExamMinute: 0,
                    lastExamTime: 0,
                    fromRefId: '',
                    fromType: ''
                },
                examModal: false,
                examModalClose: false,
                hasFindChild: false,
                slideVerifyShow: false, // 滑块验证码
                slideExtStyle: {
                    position: 'fixed',
                    left: '50%',
                    top: '50%',
                    '-webkit-transform': 'translate(-50%,-50%)',
                    transform: 'translate(-50%,-50%)'
                },
                cameraDialog: false,
                faceCheckPass: false
            }
        },
        // 离开页面后销毁播放器，定时器
        // beforeDestroy() {
        //     this.clearTimeEightTop();
        // },
        mounted() {

            // this.socketApi.exitCallback(this.exitCallback);
        },
        methods: {
            open: function (courseId) {
                this.courseId = courseId;
                this.playerDrawer = true
                this.studyId = this.$route.query.studyId;
                if (this.studyId===undefined || this.studyId===null) {
                    this.studyId = ''
                }
                this.fromRefId = this.$route.query.fromRefId;
                if (this.fromRefId===undefined || this.fromRefId===null) {
                    this.fromRefId = ''
                }
                this.getDetail(this.courseId, this.studyId, this.fromRefId);
                // console.log(this.info)
                this.$nextTick(() => {
                    // 添加到controlBar中
                    // this.player = this.$refs.videoPlayer.player;
                });
            },
            setTestData: function () {
                if (process.env.NODE_ENV === 'dev') {
                    if (this.playerOptions.relationType === 'VIDEO') {
                        this.playerOptions.playPathUrl = 'http://wxsnsdy.tc.qq.com/105/20210/snsdyvideodownload?filekey=30280201010421301f0201690402534804102ca905ce620b1241b726bc41dcff44e00204012882540400&bizid=1023&hy=SH&fileparam=302c020101042530230204136ffd93020457e3c4ff02024ef202031e8d7f02030f42400204045a320a0201000400';
                        this.courseConfig.playExam = 3;
                        this.courseConfig.playExamMinute = 0.1
                    } else if (this.playerOptions.relationType === 'AUDIO') {
                        this.playerOptions.playPathUrl = 'http://qasfiles.qdrxhz.cn/group1/M00/00/05/rBUABGJFiTKAC9_QADeqDvCR14s028.mp3';
                        this.courseConfig.playExam = 3;
                        this.courseConfig.playExamMinute = 0.1
                    } else if (this.playerOptions.relationType === 'ARTICLE') {
                        // this.playerOptions.articleContent = '';
                    }
                }
            },
            playStart: function () {
                if (this.playerOptions.relationType === 'VIDEO') {
                    this.$refs.cvideoPlayer.play();
                } else if (this.playerOptions.relationType === 'AUDIO') {
                    this.$refs.audioPlayer.play();
                } else if (this.playerOptions.relationType === 'ARTICLE') {
                    console.log(1)
                }
            },
            playPause: function () {
                if (this.playerOptions.relationType === 'VIDEO') {
                    this.$refs.cvideoPlayer.pause();
                } else if (this.playerOptions.relationType === 'AUDIO') {
                    this.$refs.audioPlayer.play();
                } else if (this.playerOptions.relationType === 'ARTICLE') {
                    console.log(1)
                }
            },
            //返回上一页
            goBack: function () {
               this.playerDrawer = false
            },
            exitCallback: function () {
                try {
                    // if (this.player.isFullscreen()) {
                    //     this.player.exitFullscreen(); // 强制退出全屏，恢复正常大小
                    // }
                } catch (e) {}
                try {
                    this.playPause();
                } catch (e) {}
            },
            getQrImage: function () {
                this.qrImageUrl = process.env.VUE_APP_API_HOST + 'notify/verify/getQr?bizType=COURSE&bizId=' + this.info.courseId + "&tenantId=" + this.appCookies.getTenantId()
            },
            // getChapterQr: function (courseId, chapterId) {
            //     this.chapterImageUrl = process.env.VUE_APP_API_HOST + 'notify/verify/getQr?bizType=COURSE&bizId=' + courseId + '&chapterId=' + chapterId + "&tenantId=" + this.appCookies.getTenantId();
            // },
            getChapterQr: function (chapterId) {
                this.chapterImageUrl = process.env.VUE_APP_API_HOST + 'companyId?bizType=COURSE&bizId=' + this.info.courseId + '&chapterId=' + chapterId + "&tenantId=" + this.appCookies.getTenantId();
            },
            getFiles: function (item) {

                console.log(item);
                let data = {
                    hasLogin: this.appCookies.hasLogin(),
                    userId: this.appCookies.getCookieUID(),
                    relationId: item.videoId,
                    relationType: item.relationType
                }
                this.apis.get('course/annex/file/getAnnexFile', data, false, true)
                    .then((res) => {
                        if (res.code === 1) {
                            this.filesList = res.data || [];
                            if (this.filesList && filesList.length > 0) {
                                this.playerOptions.relationType = 'IMAGE';
                                let fileId = this.filesList[0].fileId;
                                this.getImgList(fileId);
                            }
                        } else {
                            this.filesList = []
                        }
                        this.videoLoading = false;
                    }).catch(err => {
                    this.videoLoading = false;
                    console.log(err);
                });
            },
            getImgList: function (fileId) {
                if (this.filesList.length>0) {
                    this.apis.get('course/annex/file/getImgaes?fileId=' + fileId, {}, false, true)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.playerOptions.imgList = res.data || [];
                        } else {
                            this.playerOptions.imgList = []
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                } else {
                    this.$message({
                        message: '没有课件',
                        showClose: true, offset: 200, type: 'success'
                    });
                }
            },
            showQr: function (item) {
                if (item.showQr) {
                    item.showQr = false;
                } else {
                    this.getChapterQr(item.chapterId);
                    item.showQr = true;
                    console.log(item.showQr);
                }
            },
            replay () {
                this.toPlay(this.chapterInfo);
                this.endPageShow = false;
            },
            playNext () {
                let chapterId = this.chapterInfo.chapterId;
                this.hasFindChild = false;
                let chapter = this.findNext(this.chapterList, chapterId);
                this.toPlay(chapter);
            },
            findNext (children, chapterId) {
                let len = children.length
                let i = -1
                let nextChapter = {}
                while (++i < len) {
                    let item = children[i]
                    if (this.hasFindChild && item.chapterType === 3) {
                        return item;
                    }
                    if (item.chapterId === chapterId) {
                        this.hasFindChild = true;
                    } else {
                        if (item.children && item.children.length) {
                            let res = this.findNext(item.children, chapterId)
                            if (res.chapterId) return res
                        }
                    }
                }
                return nextChapter
            },
            onPlayerEnd: function (playTime) {
                this.endPageShow = true;
                this.playerOptions.relationType = ''
                this.currentTime = 0;
                this.courseConfig.lastExamTime = 0;
                if (!this.appCookies.hasLogin() || this.chapterInfo.studyStatus === 1) {
                    return;
                }
                if (this.chapterInfo.studyCid) {
                    let data = {
                        studyCid: this.chapterInfo.studyCid,
                        studyTime: playTime,
                        courseId: this.info.courseId,
                        chapterId: this.chapterInfo.chapterId,
                        userId: this.appCookies.getCookieUID()
                    };
                    this.apis.postForm('user/course/updateStatus', data, false, true)
                        .then((res) => {
                            if (res.code === 1) {
                                this.chapterInfo.studyStatus = 1;
                                this.$forceUpdate();
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 400, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            },
            toPlay: function (chapter) {
                // console.log(chapter)
                this.chapterInfo = chapter;
                if (!chapter.videoId) {
                    return
                }
                if (!chapter || !chapter.chapterId) {
                    return
                }
                // console.log(chapter)
                this.endPageShow = false;
                let that = this;
                if (chapter.canFree === 1) {
                    // 获取chater info
                    if (this.info.hasEnroll) {
                        console.log(this.info.hasEnroll)
                        // 如果学习ID 为空则提示 是否需要选择 学习哪次报名的记录，课程列表已经返回了
                        this.studyId = this.info.studyId; // 临时设置为 课程 随机选择的
                    }
                    let data = {
                        courseId: chapter.courseId,
                        studyId: this.studyId,
                        chapterId: chapter.chapterId,
                        hasLogin: this.appCookies.hasLogin(),
                        userId: this.appCookies.getCookieUID()
                    }
                    this.apis.get('/portal/course/getChapterInfoV2', data, false, true)
                        .then((res) => {
                            if (res.code === 1) {
                                let retInfo = res.data;
                                console.log(retInfo)
                                that.playerOptions.relationType = retInfo.relationType;
                                this.chapterInfo.canFree = retInfo.canFree;
                                this.chapterInfo.videoId = retInfo.videoId;
                                this.chapterInfo.chapterId = retInfo.chapterId;
                                this.chapterInfo.courseId = retInfo.courseId;
                                this.chapterInfo.studyId = retInfo.studyId;
                                this.chapterInfo.studyCid = retInfo.studyCid;
                                this.chapterInfo.studyStatus = retInfo.studyStatus;
                                this.chapterInfo.studyTime = retInfo.studyTime;
                                this.chapterInfo.videoTime = retInfo.videoTime;
                                this.chapterInfo.videoTimeStr = retInfo.videoTimeStr;
                                this.courseConfig.lastExamTime = 0;
                                that.playerOptions.posterImg = retInfo.videoImgUrl;
                                if (!that.playerOptions.posterImg) {
                                    that.playerOptions.posterImg = this.info.courseImgUrl;
                                }
                                if (retInfo.relationType==='ARTICLE') {
                                    that.playerOptions.articleContent = retInfo.htmlContent;
                                } else {
                                    that.playerOptions.playPathUrl = retInfo.videoPathUrl;
                                    if (that.chapterInfo.studyStatus === 1) {
                                        that.playerOptions.playbackRates = [0.5, 1.0, 1.5, 2.0];
                                        that.playerOptions.currentTime = 0;
                                    } else {
                                        if (that.chapterInfo.studyTime) {
                                            that.playerOptions.currentTime = that.chapterInfo.studyTime;
                                        } else {
                                            that.playerOptions.currentTime = 0;
                                        }
                                    }
                                }
                                this.setTestData() // 测试环境测试数据
                                // 设置播放信息
                                this.playerOptions.fastForward = this.courseConfig.fastForward;
                                this.playerOptions.studyStatus = that.chapterInfo.studyStatus;
                                this.playerOptions.playExam = that.courseConfig.playExam;
                                this.playerOptions.playExamMinute = that.courseConfig.playExamMinute;
                                window.scroll(0, 0);
                                // 打开摄像头
                                this.openCamera();
                            } else {
                                this.toLoginOrEnroll()
                            }
                        }).catch(err => {
                        this.showSpin = false;
                        console.log(err);
                    });

                } else {
                    this.toLoginOrEnroll()
                    // this.$Modal.warning({
                    //     title: '未报名不可播放',
                    //     content: '请报名后观看'
                    // });
                }
            },
            /**
             * 确认是否是真人答题 或随机试题
             */
            toConfirmIsHuman: function () {
                if (this.courseConfig.playExam === 1) {
                    // 暂停播放，弹出试题
                    this.playPause();
                    this.toRandomItem()
                } else if (this.courseConfig.playExam === 2) {
                    // 暂停播放，弹出滑块
                    this.playPause();
                    this.toShowSlideVerify()
                } else if (this.courseConfig.playExam === 3) {
                    // 进行拍照上传后台进行记录 和 人脸对比
                }
            },
            updateTime: function (playTime) {
                this.chapterInfo.studyTime = playTime;
                this.$forceUpdate();
                if (this.chapterInfo.studyCid) {
                    let data = {
                        studyCid: this.chapterInfo.studyCid,
                        studyTime: playTime,
                        courseId: this.info.courseId,
                        chapterId: this.chapterInfo.chapterId,
                        userId: this.appCookies.getCookieUID()
                    };
                    this.apis.postForm('user/course/updateTime', data, false, true)
                        .then((res) => {
                            if (res.code !== 1) {
                                this.$message({
                                    message: '学习进度记录失败',
                                    showClose: true, offset: 400, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            },
            openCamera: function () {
                // 如果是实时监控需要打开摄像头
                if (this.courseConfig.playExam === 3) {
                    if (this.cameraDialog === false) {
                        this.cameraDialog = true;
                        this.$nextTick(() => {
                            if (!this.$refs.cameraBox.isOpenSuccess()) {
                                this.$refs.cameraBox.init()
                            }
                            this.videoLoadingTxt = '请完成人脸识别验证'
                            this.videoLoading = true
                        })
                    }
                }
            },
            toUploadFace: function (base64) {
                // 摄像头回调返回base64
                let that = this;
                let data = {
                    phone: '', // this.appCookies.getCookiePhnoe(),
                    faceImg: base64
                }
                this.oesApis.postJson('face/recog/match', data, false, false)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message.success({
                                message: "人脸识别成功;",
                                offset: 380,
                                duration: 1000
                            });
                            this.faceCheckPass = true
                            this.videoLoading = false
                            this.$refs.cameraBox.trackerStop();
                            that.playStart();
                        } else {
                            this.$message.error({
                                message: '人脸监控认证失败，请确认本人学习或调整摄像头位置',
                                offset: 380,
                                duration: 2000
                            });
                            that.playPause();
                            this.faceCheckPass = false
                            this.videoLoading = true
                            this.$refs.cameraBox.trackerStart();
                        }
                        this.mainLoading = false;
                    }).catch((err) => {
                    this.mainLoading = false;
                    console.log(err)
                });
            },
            handlerMatch: function () {
                this.$refs.cameraBox.getPhoto(10);
            },
            toShowSlideVerify: function () {
                this.slideVerifyShow = true;
            },
            slideVerifyCallback: function () {
                this.slideVerifyShow = false;
                this.playStart();
            },
            toRandomItem() {
                let queryParams = {
                    courseId: this.info.courseId,
                    chapterId: this.chapterInfo.chapterId,
                    gnvqId: this.info.gnvqId,
                    levelId: this.info.levelId,
                    pointId: this.chapterInfo.pointId,
                    baseCatId: this.chapterInfo.baseCatId,
                    userId: this.appCookies.getCookieUID(),
                }
                this.apis.get('user/chapter/exam/getRandomItemCount', queryParams, false, true)
                    .then((res) => {
                        if (res.code === 1 && res.data > 0) {
                            // 弹框做题
                            this.examModal = true;
                            this.examModalClose = false;
                            this.$nextTick(() => {
                                this.$refs.examRandomPage.getItem(
                                    this.info.courseId,
                                    this.chapterInfo.studyId,
                                    this.chapterInfo.chapterId,
                                    this.info.gnvqId,
                                    this.info.levelId,
                                    this.chapterInfo.pointId,
                                    this.chapterInfo.baseCatId
                                );
                            })
                        } else {
                            this.playStart();
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            closeExamModal: function (type) {
                if (type === 0) {
                    this.examModalClose = true;
                }
                if (type === 1) {
                    this.examModal = false;
                    this.playStart(); // 播放
                }
            },
            getDetail: function (courseId, studyId, fromRefId) {
                this.videoLoading = true;
                let data = {
                    courseId: courseId,
                    hasLogin: this.appCookies.hasLogin(),
                    userId: this.appCookies.getCookieUID(),
                    studyId: studyId,
                    fromRefId: fromRefId
                }
                this.apis.get('portal/course/getById', data, false, true)
                    .then((res) => {
                        if (res.code === 1) {
                            this.info = res.data || {};
                            this.playerOptions.posterImg = this.info.courseImgUrl;
                            this.getCourseConfig(this.info.courseId, this.info.studyId, this.info.fromRefId);
                            this.getChapterList(this.info.courseId, this.info.studyId, this.info.fromRefId);
                        } else {
                            this.info = {};
                            this.videoLoading = false;
                        }

                    }).catch(err => {
                    this.videoLoading = false;
                    console.log(err);
                });
            },
            getChapterList: function (courseId, studyId, fromRefId) {
                let data = {
                    hasLogin: this.appCookies.hasLogin(),
                    userId: this.appCookies.getCookieUID(),
                    studyId: studyId,
                    fromRefId: fromRefId
                }
                this.apis.get('portal/course/getChapterListV2/' + courseId, data, false, true)
                    .then((res) => {
                        if (res.code === 1) {
                            this.chapterList = res.data || [];
                        } else {
                            this.chapterList = {}
                        }
                        this.videoLoading = false;
                    }).catch(err => {
                    this.videoLoading = false;
                    console.log(err);
                });
            },
            getCourseConfig: function (courseId, studyId, fromRefId) {
                if (!this.appCookies.hasLogin()) {
                    return;
                }
                let data = {
                    userId: this.appCookies.getCookieUID(),
                    courseId: courseId,
                    studyId: studyId,
                    fromRefId: fromRefId,
                }
                this.apis.get('portal/course/getCourseConfig', data, false, true)
                    .then((res) => {
                        if (res.code === 1) {
                            this.courseConfig = res.data || {};
                            if (this.courseConfig.fastForward === 0) {
                                this.playerOptions.playbackRates = [];
                            }
                        }
                    }).catch(err => {
                    this.videoLoading = false;
                    console.log(err);
                });
            },
            toLoginOrEnroll: function () {
                if (!this.appCookies.hasLogin()) {
                    // this.$parent.toLogin();
                    this.$confirm('请登录后操作', '提示', {
                    confirmButtonText: '去登录',
                    cancelButtonText: '取消',
                    type: 'warning'
                  }).then(() => {
                    //   sessionStorage.setItem('redirectUrl', '/courseplayer?courseId' + this.$route.query.courseId);
                    //   this.$router.push({ path: '/' });
                      this.$router.go(-1);
                  }).catch(() => {
                  });
                } else {
                  this.$confirm('未报名或学习已结束,请报名后操作', '提示', {
                    confirmButtonText: '去报名',
                    cancelButtonText: '取消',
                    type: 'warning'
                  }).then(() => {
                    this.$router.push({
                        path: '/main/enroll',
                        query: {
                            categoryId: this.info.gnvqCatId,
                            gnvqId: this.info.gnvqId,
                            levelId: this.info.levelId
                        }
                    });
                  }).catch(() => {
                  });
                    // this.$Modal.confirm({
                    //     title: '提示',
                    //     content: '未报名或学习已结束,请报名后操作',
                    //     okText: '去报名',
                    //     onOk: () => {
                    //         this.$router.push({
                    //             path: '/main/enroll',
                    //             query: {
                    //                 categoryId: this.info.gnvqCatId,
                    //                 gnvqId: this.info.gnvqId,
                    //                 levelId: this.info.levelId
                    //             }
                    //         });
                    //     },
                    //     onCancel: () => {
                    //     }
                    // });
                }
            },
            toChapterExam: async function (pointId, can) {
                if (this.appCookies.hasLogin() && can === 1) {
                    let data = {
                        courseId: this.info.courseId,
                        gnvqId: this.info.gnvqId,
                        levelId: this.info.levelId,
                        pointId: pointId,
                        userId: this.appCookies.getCookieUID()
                    }
                    let count = await this.getRandomItemCount();
                    if (count === 0) {
                        this.$message({
                            message: '此章节无课后练习题',
                            showClose: true, offset: 400, type: 'error'
                        });
                        return;
                    }
                    this.apis.get('/user/enrollOrder/checkHasEnrollByGnvq', data, false, true)
                        .then((res) => {
                            if (res.code === 1) {
                                let data = {
                                    userId: this.appCookies.getCookieUID(),
                                    courseId: this.info.courseId,
                                    gnvqId: this.info.gnvqId,
                                    levelId: this.info.levelId,
                                    pointId: pointId,
                                    exerciseType: 'COURSE',
                                    regen: 0
                                }
                                this.apis.postForm('user/exercise/genExerciseByGnvq', data, false, true)
                                    .then((res) => {
                                        if (res.code === 1) {
                                            this.$router.push({
                                                path: '/main/exam/exercise',
                                                query: {
                                                    mstId: res.data.mstId
                                                }
                                            });
                                        } else if (res.code === -2) {
                                            this.$router.push({
                                                path: '/main/exam/exercise',
                                                query: {
                                                    mstId: res.data.mstId
                                                }
                                            });
                                        } else {
                                            this.$message({
                                                message: res.msg,
                                                showClose: true, offset: 400, type: 'error'
                                            });
                                        }
                                    }).catch(err => {
                                    console.log(err);
                                });
                            } else {
                                this.toLoginOrEnroll();
                                // this.$Modal.warning({
                                //     title: '您未报名此工种等级',
                                //     content: '请确报名后操作'
                                // });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                    // this.$router.push({
                    //     path: '/main/exam/practice',
                    //     query: {
                    //         courseId: this.info.courseId,
                    //         chapterId: chapterId,
                    //         userId: this.appCookies.getCookieUID()
                    //     }
                    // });
                } else {
                    this.toLoginOrEnroll();
                    // this.$Modal.warning({
                    //     title: '未登录或未报名不可进行课后练习',
                    //     content: '请确认是否已经登录或已报名此工种等级'
                    // });
                }
            },
            getRandomItemCount: async function(data) {
                await this.apis.get('user/exercise/getRandomItemCount', data, false, true)
                    .then((res) => {
                        if (res.code === 1) {
                            return res.data;
                        } else {
                            return 0;
                        }
                    }).catch(err => {
                        console.log(err);
                        return 0;
                    });
            },
        }
    }
</script>

<style scoped lang="scss">
.course-player{
    height: 100vh;
    // overflow: hidden;
    overflow-y: hidden;
    overflow-x: auto;
}
.course-player-header{
  padding: 0 30px;
  height: 50px;
  background: #F4F5F9;
  display: flex;
  align-items: center;
  .header-back{
    cursor: pointer;
  }
  h2{
    margin-left: 32px;
    margin-right: 54px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    cursor: pointer;
  }
  .header-share{
    height: 65px;
    line-height: 65px;
    .share-btn{
      margin-left: 10px;
      vertical-align: middle;
      cursor: pointer;
    }
  }
  // ::v-deep .el-popover{
  //   width: 160px!important;
  // }
  // ::v-deep .el-popper{
  //   width: 160px!important;
  // }
  .share-qr{//没生效
    background-color: #FFFFFF;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 20%);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 20%);
    white-space: nowrap;
    z-index: 999999;
    line-height: 16px;
    text-align: center;
    width: 160px;
    margin: 0 auto;
    p{
      margin: 0;
      font-size: 16px;
      text-align: center;
    }
  }
}
.course-player-main{
  position: relative;
  background-color: #333333;
  display: flex;
  height: calc(100vh - 52px);
    box-sizing: border-box;
  .nav-btns{
    width: 64px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    .nav-btn{
      margin: 16px auto;
      cursor: pointer;
      img{
        width: 28px;
        height: 28px;
      }
      div{
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #A4A5A6;
        line-height: 20px;
      }
    }
  }
  .player-box{
    background-color: #000000;
    flex: 1;
    width: 1px;//解决弹性框右边自适应子项目overflow: hidden;无效问题
    .player-wrapper{
        height: 100%;
      /*播放器*/
      ::v-deep .video-player{
          // flex: 1;
          height: calc(100vh - 50px);
          .vjs-fluid{
              padding-top: 0;
          }
          .video-js{
              height: 100%;
              video{
                  height: 100%;
              }
              .momodefine {
                  color: #fff !important;
                  font-size: 16px;
                  text-shadow: 2px 2px #333;
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden !important;
                  position: absolute !important;
                  animation: momoAnimationTextF 8s linear infinite; /* js中的8秒，原因在这里 */
                  z-index: 10100;
                  left: 50%;
                  top: 300px;
              }
              @keyframes momoAnimationTextF{
                  from {
                      left:150px;
                      transform: translateX(-100%);
                  }
                  to {
                      left:90%;
                      transform: translateX(0);/* left 101%是因为不让客户看到闪动的高度变化 */
                  }
              }
          }
      }
      .player-default{
        height: calc(100vh - 52px);
        img{
          width: 100%;
          height: 100%;
        }
      }
      .player-end{
        height: calc(100vh - 52px);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .chapter-list::after{
      content: "";
      display: block;
      height: 200px;
      visibility: hidden;
  }
  .chapter-list{
    // position: absolute;
    // left: 64px;
    width: 390px;
    height: 100%;
    overflow: auto;
    background: #26292C;
    padding-top: 10px;
    padding-left: 8px;
    padding-right: 8px;
    flex-shrink: 0;
    // padding-bottom: 200px;
    z-index: 1000;
    img{
      margin-right: 4px;
      vertical-align: middle;
    }
    h3{
      cursor: pointer;
    }
    .list-item{
      .list-item-self{
        position: relative;
        // height: 26px;
        // line-height: 26px;
        .list-item-self-line1{
            display: flex;
            justify-content: space-between;
            line-height: 24px;
            .list-item-self-left{
                flex-grow: 1;
                display: flex;
                align-items: center;
                overflow: hidden;
                .list-item-self-icon{
                    flex-shrink: 0;
                    width: 16px;
                    height: 16px;
                }
            }
            .list-item-self-right{
                color: #EE4137;
                font-size: 12px;
                font-family: Microsoft YaHei;
                flex-shrink: 0;
                text-align: right;
                .list-item-file-btn{
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #EE4137;
                    white-space: nowrap;
                    cursor: pointer;
                    padding: 2px 6px;
                    background-color: rgb(63, 63, 63);
                    border-radius: 2px;
                }
                .list-item-file-btn:hover{
                    background-color: #26292C;
                }
            }
        }
        .list-item-self-line2{
            margin-left: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #EE4137;
            font-size: 12px;
            font-family: Microsoft YaHei;
            flex-shrink: 0;
            line-height: 24px;
            .time{
                flex-grow: 1;
                color: #999999;
            }
            .list-item-state{
                color: #FFFFFF;
                white-space: nowrap;
            }
            .list-item-qr-box{
                position: relative;
                .list-item-qr-icon{
                    cursor: pointer;
                }
                .list-item-qr{
                    display: none;
                    position: absolute;
                    top: 32px;
                    left: 0;
                    background-color: #FFFFFF;
                    border-radius: 4px;
                    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 20%);
                    box-shadow: 0 1px 6px rgba(0, 0, 0, 20%);
                    white-space: nowrap;
                    z-index: 999999;
                    padding-bottom: 20px;
                    text-align: center;
                    p{
                        margin: 0;
                    }
                }
            }
            .list-item-qr-box:hover .list-item-qr{
                display: block;
            }
        }
      }
      .list-item-self:hover{
        background-color: rgb(67, 67, 67);
      }
    //   .list-item-qr-icon:hover .list-item-qr{
    //     display: block;
    //   }
    }
    .list-item-level1{
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .list-item-children{
      margin-left: 8px;
    }
    .list-item-text{
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #FFFFFF;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 32px;
    }
    .knowledge-item-text{
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #A8A9AB;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
        padding-left: 4px;
    }
    .list-knowledge-item{
      margin-left: 32px;
    }
  }
}
.camera-dialog2 {
    width: 310px;
    position: fixed;
    top: 4px;
    right: 4px;
    left: auto;
    bottom: auto;
    overflow: visible;
    ::v-deep .el-dialog{
        // margin-top: 0 !important;
    }
    ::v-deep {
        .el-dialog__body{
            height: 310px;
            padding: 4px 4px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .v-modal {
            position: fixed;
        }
    }
}
.camera-dialog {
    width: 310px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    overflow: visible;
    ::v-deep {
        .el-dialog__body{
            height: 310px;
            padding: 4px 4px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .v-modal {
            position: static;
        }
    }
}
</style>
